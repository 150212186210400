// 28, 32, 34, or 36
// exception: Drow characters do not use the standard 28/32/34/36 build point progression that all other races use; instead, 
// they always start with 28, and once reincarnated improve to 30 and then a final maximum of 32.
const StatusTitle = (number, isDrow) => {
    if (isDrow) {
        switch (number) {
            default:
            case 28:
                return "Champion";
            case 30:
                return "Hero";
            case 32:
                return "Legend";
        }
    } else {
        switch (number) {
            default:
            case 28:
                return "Adventurer";
            case 32:
                return "Champion";
            case 34:
                return "Hero";
            case 36:
                return "Legend";
        }
    }
}

export default StatusTitle;