import React, { Component } from 'react';
import CharacterSearch from './CharacterSearch';
import { Container, Row } from 'react-bootstrap';

class Home extends Component {
    render() {
        const { authService } = this.props;
        return (
            <Container fluid>
                <Row>
                    <CharacterSearch authService={authService} />
                </Row>
            </Container>
        );
    }
}

export default Home;