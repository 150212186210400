import React, { Component } from 'react';
import FeatCategory from './FeatCategory';

class FeatListing extends Component {
    render() {
        const { featData } = this.props;
        return (
            <div id="charSheetFeatsTab">
                <div className="charSheetFeatsText">
                    {featData.featCategories ? 
                        <FeatCategory categories={featData.featCategories} />
                    : null}
                </div>
            </div>
        );
    }
}

export default FeatListing;