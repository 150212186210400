const XpToNextLevel = (level, pastLives) => {
    var heroicXpToNextLevel = 0;
    if (level > 20)
        level = 20;
    if (pastLives === 0) {
        switch (level) {
            default:
            case 0:
            case 1:
                heroicXpToNextLevel = 4000;
                break;
            case 2:
                heroicXpToNextLevel = 16000;
                break;
            case 3:
                heroicXpToNextLevel = 40000;
                break;
            case 4:
                heroicXpToNextLevel = 72000;
                break;
            case 5:
                heroicXpToNextLevel = 112000;
                break;
            case 6:
                heroicXpToNextLevel = 160000;
                break;
            case 7:
                heroicXpToNextLevel = 225000;
                break;
            case 8:
                heroicXpToNextLevel = 305000;
                break;
            case 9:
                heroicXpToNextLevel = 400000;
                break;
            case 10:
                heroicXpToNextLevel = 510000;
                break;
            case 11:
                heroicXpToNextLevel = 630000;
                break;
            case 12:
                heroicXpToNextLevel = 760000;
                break;
            case 13:
                heroicXpToNextLevel = 900000;
                break;
            case 14:
                heroicXpToNextLevel = 1050000;
                break;
            case 15:
                heroicXpToNextLevel = 1210000;
                break;
            case 16:
                heroicXpToNextLevel = 1375000;
                break;
            case 17:
                heroicXpToNextLevel = 1545000;
                break;
            case 18:
                heroicXpToNextLevel = 1720000;
                break;
            case 19:
                heroicXpToNextLevel = 1900000;
                break;
            case 20:
                heroicXpToNextLevel = 1900000;
                break;
        }
    } else if (pastLives === 1) {
        switch (level) {
            default:
            case 1:
                heroicXpToNextLevel = 6000;
                break;
            case 2:
                heroicXpToNextLevel = 24000;
                break;
            case 3:
                heroicXpToNextLevel = 60000;
                break;
            case 4:
                heroicXpToNextLevel = 108000;
                break;
            case 5:
                heroicXpToNextLevel = 168000;
                break;
            case 6:
                heroicXpToNextLevel = 240000;
                break;
            case 7:
                heroicXpToNextLevel = 337500;
                break;
            case 8:
                heroicXpToNextLevel = 457500;
                break;
            case 9:
                heroicXpToNextLevel = 600000;
                break;
            case 10:
                heroicXpToNextLevel = 765000;
                break;
            case 11:
                heroicXpToNextLevel = 945000;
                break;
            case 12:
                heroicXpToNextLevel = 1140000;
                break;
            case 13:
                heroicXpToNextLevel = 1350000;
                break;
            case 14:
                heroicXpToNextLevel = 1575000;
                break;
            case 15:
                heroicXpToNextLevel = 1815000;
                break;
            case 16:
                heroicXpToNextLevel = 2062500;
                break;
            case 17:
                heroicXpToNextLevel = 2317500;
                break;
            case 18:
                heroicXpToNextLevel = 2580000;
                break;
            case 19:
                heroicXpToNextLevel = 2850000;
                break;
            case 20:
                heroicXpToNextLevel = 2850000;
                break;
        }
    } else if (pastLives >= 2) {
        switch (level) {
            default:
            case 1:
                heroicXpToNextLevel = 8000;
                break;
            case 2:
                heroicXpToNextLevel = 32000;
                break;
            case 3:
                heroicXpToNextLevel = 80000;
                break;
            case 4:
                heroicXpToNextLevel = 144000;
                break;
            case 5:
                heroicXpToNextLevel = 224000;
                break;
            case 6:
                heroicXpToNextLevel = 320000;
                break;
            case 7:
                heroicXpToNextLevel = 450000;
                break;
            case 8:
                heroicXpToNextLevel = 610000;
                break;
            case 9:
                heroicXpToNextLevel = 800000;
                break;
            case 10:
                heroicXpToNextLevel = 1020000;
                break;
            case 11:
                heroicXpToNextLevel = 1260000;
                break;
            case 12:
                heroicXpToNextLevel = 1520000;
                break;
            case 13:
                heroicXpToNextLevel = 1800000;
                break;
            case 14:
                heroicXpToNextLevel = 2100000;
                break;
            case 15:
                heroicXpToNextLevel = 2420000;
                break;
            case 16:
                heroicXpToNextLevel = 2750000;
                break;
            case 17:
                heroicXpToNextLevel = 3090000;
                break;
            case 18:
                heroicXpToNextLevel = 3440000;
                break;
            case 19:
                heroicXpToNextLevel = 3800000;
                break;
            case 20:
                heroicXpToNextLevel = 3800000;
                break;
        }
    }

    return heroicXpToNextLevel;
}

export default XpToNextLevel;