import React from 'react';

// recursive text colorizor
export default function Colorize(uncoloredText) {
    if (uncoloredText.length === 0)
        return

    let textParts = uncoloredText.replace(/<[/]rgb>|<[/]RGB>/gm, '').replace(/\\#/gm,'#')

    var coloredParts = textParts.replace("\\!", '!').split(/(?:<RGB|<rgb=)(#\w{3,})(?:>)(.*)/g).filter(Boolean)
    if (coloredParts.length > 1) {
        if (coloredParts[0].indexOf("#") === 0) {
            return (
                <span style={{ color: coloredParts[0] }}>{Colorize(coloredParts.slice(1, coloredParts.length).join())}</span>
            )
        } else {
            if (coloredParts[1].indexOf("#") === 0) {
                return (
                    <span>{coloredParts[0]}<span style={{ color: coloredParts[1] }}>{Colorize(coloredParts.slice(2, coloredParts.length).join())}</span></span>
                )
            } else {
                return (
                    coloredParts.join()
                )
            }
        }
    } else {
        if (coloredParts !== undefined && coloredParts.length === 1) {
            if (coloredParts[0].indexOf("<") === 0) {
                coloredParts = coloredParts[0].split(/(?:<RGB|<rgb=)(#\w{3,})(?:>)(.*)/g).filter(Boolean)
                if (coloredParts[0].indexOf("#") === 0) {
                    return (
                        <span style={{ color: coloredParts[0] }}>{Colorize(coloredParts.slice(1, coloredParts.length).join())}</span>
                    )
                } else {
                    if (coloredParts[1] !== undefined && coloredParts[1].indexOf("#") === 0) {
                        return (
                            <span>{coloredParts[0]} <span style={{ color: coloredParts[1] }}>{Colorize(coloredParts.slice(2, coloredParts.length).join())}</span></span>
                        )
                    } else {
                        return (
                            coloredParts.join()
                        )
                    }
                }

            } else {

                return (
                    coloredParts[0]
                )
            }

        }

        return null;
    }
}