
import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { useAuth } from 'react-oauth2-pkce';

// Wrapper makes sure you're logged in before rendering the child component:
const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const { authService } = useAuth();
  const isAuthenticated = authService.isAuthenticated();

  useEffect(() => {
    if (authService.isPending() || authService.isAuthenticated()) {
      return;
    }
    const fn = async () => {
      await authService.login({
        appState: {targetUrl: window.location.pathname}
      });
    };
    fn();
  }, [authService, path]);

  const render = props =>
    isAuthenticated === true ? <Component {...props} authService={authService} /> : null;

  return <Route path={path} render={render} {...rest} />;
};

export default PrivateRoute;