import React, { Component } from 'react';
import { Container, Col } from 'react-bootstrap';

class Guild extends Component {
    render() {
        return (
            <Container>
                <Col>
                    Guilds...
                </Col>
            </Container>
        );
    }
}

export default Guild;