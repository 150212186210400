import React, { Component } from 'react';
import { ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';

/*
*   This function will be used to display all of the public characters, on each server.
*/
class SnapshotList extends Component {
    // Starting state:
    constructor(props) {
        super(props)
        this.state = {
            snapshots: null,
            Error: null,
            HasResults: false
        }
    }

    componentDidMount() {
        if (this.props.snapshots !== undefined) {
            this.setState({ snapshots: this.props.snapshots }, () => {
                console.log("Snapshotlist loading...");
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.snapshots !== undefined) {
            if (prevProps.snapshots !== this.props.snapshots) {
                this.setState({ snapshots: this.props.snapshots }, () => {
                    console.log("Snapshotlist updating...");
                })
            }
        }
    }

    render() {
        const { snapshots } = this.state;
        const { character, server } = this.props;

        if (snapshots === undefined || snapshots === null)
            return null;

        return (
            <ListGroup className="snapshotList">
                <ListGroup.Item className="snapshotItemHeader">
                    Snapshots for <Link to={"/character/" + server + "/" + character}>{character} on {server}</Link>
                </ListGroup.Item>
                {snapshots.map((snapshot, i) => {
                    var dateTime = new Date(snapshot.dateTaken)
                    return (
                        <Link key={i} to={"/character/" + snapshot.server + "/" + snapshot.characterName + "/snapshots/" + snapshot.snapshotName}><ListGroup.Item className={(i % 2) === 0 ? "snapshotItem snapcolor-primary": "snapshotItem snapcolor-secondary"}>{snapshot.snapshotName} - {dateTime.toLocaleDateString() + " "+ dateTime.toLocaleTimeString()}</ListGroup.Item></Link>
                    )
                })}
            </ListGroup>
        );
    }
}

export default SnapshotList;