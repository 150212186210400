import React, { Component } from 'react';

import Plus from '../images/tabs/plus-icon.png'
import Minus from '../images/tabs/minus-icon.png'
import collapse from './utils/Collapser'

class FeatCategory extends Component {
    constructor(props) {
        super(props)
        this.state = {
            collapsedNames: []
        }
    }

    render() {
        const { categories } = this.props;
        const { collapsedNames } = this.state;
        if (categories !== undefined) {
            return (
                categories.map((fc, i) => {
                    let collapsed = collapsedNames.length <= 0 ? false : collapsedNames.includes(fc.name) ? true : false;
                    return (
                        <div className={collapsed === false ? "charSheetFeatCategory" : "charSheetFeatCategoryCollapsed"} key={i}>
                            <div className="collapser" onClick={collapse.bind(this, fc.name, collapsedNames)}>
                                {collapsed === false ?
                                    <img className="collapseButton" src={Minus} alt="collapse" onClick={collapse.bind(fc.name, collapsedNames)} />
                                    :
                                    <img className="collapseButton" src={Plus} alt="expand" onClick={collapse.bind(fc.name, collapsedNames)} />}
                            </div>
                            <img onClick={collapse.bind(this, fc.name, collapsedNames)} alt={fc.name} src={`https://content.vaultofkundarak.com/icons/${fc.iconId.toString(16).toUpperCase()}.png`} />
                            <span onClick={collapse.bind(this, fc.name, collapsedNames)}>{fc.name}</span>
                            {fc.feats && collapsed === false ?
                                <div className="charSheetFeat">
                                    {fc.feats.map((f, c) => {
                                        return (
                                            <div key={c} className="charSheetFeatName">
                                                <img alt={f.name} src={`https://content.vaultofkundarak.com/icons/${f.iconId.toString(16).toUpperCase()}.png`} />
                                                {f.name}{f.numberTimesAcquired > 1 ? " (" + f.numberTimesAcquired + ")" : null}
                                            </div>
                                        )
                                    })}
                                </div>
                                : null}
                            {fc.categories && collapsed === false ?
                                <div className="charSheetFeatSubCategory" >
                                    <FeatCategory categories={fc.categories} />
                                </div>
                                : null}
                        </div>
                    );
                })
            );
        } else {
            return (<div></div>)
        }
    }
}

export default FeatCategory;