import React, { Component } from 'react';
import { InputGroup, Button, FormControl, Container, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import Character from './Character';
import Servers from './Servers';
import cookie from 'react-cookies';

import '../css/CharacterSearch.css';

const dot = (color = '#ccc') => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 10,
        width: 10,
    },
});

const dropdownStyles = {
    control: styles => ({ ...styles, backgroundColor: '#333', color: "red" }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isDisabled
                ? "#0E1931"
                : isSelected
                    ? "#333"
                    : isFocused
                        ? "#444"
                        : "#0E1931",

            color: isDisabled ? '#888' : isSelected
                ? "#fff"
                : "#999",

            cursor: isDisabled ? 'not-allowed' : 'default',

            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled && (isSelected ? "#444" : "#333"),
                color: !isDisabled && "#2FD5E7",
            },
            ':hover': {
                ...styles[':hover'],
                backgroundColor: !isDisabled && (isSelected ? "#444" : "#333"),
                color: !isDisabled && "#2FD5E7",
            },
        };
    },
    input: styles => ({ ...styles, ...dot() }),
    placeholder: styles => ({ ...styles, ...dot() }),
    singleValue: (styles) => ({ ...styles, color: "white", ...dot("#2FD5E7") }),
};

class CharacterSearch extends Component {
    // Starting state:
    constructor(props) {
        super(props)
        this.state = {
            Servers: {
                Argonnessen: [],
                Cannith: [],
                Ghallanda: [],
                Khyber: [],
                Orien: [],
                Sarlona: [],
                Thelanis: [],
                Hardcore: [],
                Wayfinder: []
            }
        }
    }

    componentDidMount() {
        var favoriteServer = cookie.load('FavoriteServerSelect')
        if (favoriteServer !== undefined && favoriteServer.length > 0) {
            this.setState({ activeServer: favoriteServer });
        }
        var lastSearch = cookie.load('LastCharacterSearched')
        if (lastSearch !== undefined && lastSearch.length > 0) {
            this.setState({ activeCharacter: lastSearch, searchInput: lastSearch });
        }
    }

    componentDidUpdate(prevProps) {
    }

    changeSelect = (option, target) => {
        this.setState({ activeServer: option.value }, () => {
            if (target.name !== undefined && target.name === "ServerSelect") {
                // store selection in fav server cookie
                cookie.save('FavoriteServerSelect', option.value, { path: "/", maxAge: 60 * 60 * 24 * 365, secure: true, sameSite: "strict" })
            }
        });
    }

    changeInput = (e) => {
        if (e.target.value !== this.state.searchInput) {
            this.setState({ searchInput: e.target.value, activeCharacter: undefined });
        }
    }

    submitInput = () => {
        if (this.state.activeCharacter !== this.state.searchInput) {
            this.setState({ activeCharacter: this.state.searchInput });
            cookie.save('LastCharacterSearched', this.state.searchInput, { path: "/", maxAge: 60 * 60 * 24 * 365, secure: true, sameSite: "strict" })
        }
    }

    render() {
        const { activeCharacter, activeServer, searchInput } = this.state;
        const { authService } = this.props;
        return (
            <Col>
                <InputGroup className="SearchInputs">
                    <Select
                        value={Servers.filter(option => option.label === activeServer)}
                        name="ServerSelect"
                        className="ServerSelect"
                        styles={dropdownStyles}
                        closeMenuOnSelect={true}
                        options={Servers}
                        placeholder="Select a server..."
                        onChange={this.changeSelect}
                    />
                    <FormControl
                        type="input"
                        className="CharacterInput"
                        placeholder="Character name"
                        aria-label="Character name"
                        aria-describedby="basic-addon2"
                        onChange={this.changeInput.bind()}
                        onKeyPress={event => {
                            if (event.key === "Enter") {
                                this.submitInput();
                            }
                        }}
                        value={activeCharacter}
                    />
                    <InputGroup.Append >
                        <Button variant="dark" onClick={this.submitInput.bind()}>Submit</Button>
                    </InputGroup.Append>
                </InputGroup>
                {activeCharacter && (activeServer !== undefined && activeServer !== null) ?
                    <Container>
                        <Row>
                            <Character character={activeCharacter} server={activeServer} authService={authService} />
                        </Row>
                    </Container>
                    :
                    <div className="SearchResults">
                        {searchInput && activeServer ? `Search for character on ${activeServer}, by clicking submit.`
                            :
                            <p>Select a server and enter a character's name in the input box above, to search for character data.</p>
                        }
                    </div>
                }
            </Col>
        );
    }
}

export default CharacterSearch;