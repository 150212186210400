import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AuthProvider, AuthService } from 'react-oauth2-pkce';
import config from './json/oauth_config.json';
import App from './components/App';

const authService = new AuthService({
  clientId: process.env.REACT_APP_CLIENT_ID || config.clientId,
  location: window.location,
  provider: process.env.REACT_APP_AUTH || config.provider,
  redirectUri: window.location.origin,
  scopes: config.scopes,
  audience: config.audience,
  autoRefresh: true
});

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider authService={authService}>
      <App />
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);