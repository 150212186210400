import React from "react";
import { Container, Col, Row } from 'react-bootstrap';
import { useAuth } from 'react-oauth2-pkce';

import '../css/Profile.css';

const Profile = () => {
  const { authService } = useAuth();

  const loading = authService.isPending()
  const user = authService.getUser()
  const token = authService.token;
  
  if (loading || !user) {
    return <div>Loading...</div>;
  }

  return (
    <Container>
      <Col>
        <Container>
          <Row>
            <div><h2>Id: {user.id}<br />Sub: {user.subscriptions}</h2><br />{token}</div>
          </Row>
        </Container>
      </Col>
    </Container>
  );
};

export default Profile;