import React, { Component } from 'react';
import { Container, Row, ListGroup, ListGroupItem } from 'react-bootstrap';
import axios from 'axios';
import Servers from './Servers';
import ServerItemList from './ServerItemList'

class Dashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loaded: false,
            Characters: [],
            ExampleItemList:
                [
                    { Name: "ServerName", ItemNames: ['Name', 'Name2'] }
                ],
        }
    }

    _getCharacters = async () => {
        await this.getCharacters()
    }

    // This will get a character list, per server? of each the associated characters 
    getCharacters = async () => {
        const { authService } = this.props
        if (authService.isAuthenticated()) {
            var apiEndpoint = process.env.REACT_APP_SERVICE;
            let tokens = authService.getAuthTokens()
            //const { getTokenSilently } = this.context;
            if (tokens.access_token !== undefined) {
                Servers.forEach((server) => {
                    const url = apiEndpoint + '/character/' + server.value;
                    // use axios to fetch the character from the api:
                    const options = {
                        headers: {
                            'Authorization': `Bearer ${tokens.access_token}`
                        }
                    };
                    //console.log("Contacting API: " + url);
                    axios.get(url, options)
                        .then(res => {
                            if (res.data.length > 0) {
                                let totalItems = res.data.length;
                                var characterArray = []
                                for (var i = 0; i < totalItems; i++) {
                                    let character = { Name: res.data[i].firstName, level: res.data[i].totalLevel, epicLevel: res.data[i].epicLevels, classes: res.data[i].classes, race: res.data[i].race, gender: res.data[i].gender };
                                    characterArray.push(character);
                                }
                                this.setState((prevState) => ({
                                    Characters: [...prevState.Characters, { Name: server.value, ItemNames: characterArray }],
                                    loaded: true
                                }),
                                    () => {
                                        console.log(server.value + ": " + characterArray.length)
                                    }
                                );
                            }
                        }).catch(e => {
                            this.setState((state) => ({
                                errorMessage: state.errorMessage + " " + e.message
                            }),
                                () => console.log(`${server.value} Error message: ` + this.state.errorMessage));
                        });
                })
            }
        }
    }

    /* init */
    componentDidMount() {
        //if were here, we have to be logged in due to protected route -- so let's lookup our characters, 
        //we definatley should have what we need at this point:
        this._getCharacters();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props !== prevProps) {
            // updated?!
        }
    }

    render() {
        const { loading } = this.context;
        const { loaded, errorMessage } = this.state;

        if (loading) {
            return <div>Loading...</div>;
        }

        if (!loaded) {
            return <Container><Row>Please wait while we request your characters...</Row></Container>;
        }

        if (errorMessage !== undefined && errorMessage.length > 0) {
            return <div>Could not complete request do to an error: {errorMessage}</div>;
        }

        const { Characters } = this.state;
        return (
            Characters !== undefined && Characters.length > 0 ?
                <ServerItemList ItemsPerServer={this.state.Characters} ItemLinkBase="character" />
                :
                <Container>
                    <ListGroup>
                        <ListGroupItem>No characters found.</ListGroupItem>
                    </ListGroup>
                </Container>
        )
    }
}

export default Dashboard;