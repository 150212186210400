import React from 'react';
import {
    Link
} from "react-router-dom";
import { useAuth } from 'react-oauth2-pkce';
import { Button } from 'react-bootstrap';

export default function ProfileHeaderWithAuth() {
    
    const { authService } = useAuth();

    let isAuthenticated = authService.isAuthenticated();

    const login = async () => {
        authService.authorize()
    }

    const logout = async () => {
        authService.logout()
    }

    const username = isAuthenticated ? authService.getUser() : null;

    const returnTo = process.env.REACT_APP_TROVE_RETURNTO;
    
    return (
        <nav><ul><li>
            { isAuthenticated ?
                <span id="profilename"><Link to="/profile">{username.id !== undefined ? username.id : null}</Link><span>&nbsp;</span></span>
                : null
            }
            {!isAuthenticated && (<Link to={"#"} onClick={() => login({})}><Button variant="dark">Log in</Button></Link>)}
            {isAuthenticated && <Link to={"#"} onClick={() => logout({returnTo: returnTo})}><Button variant="dark">Log out</Button></Link>}
        </li></ul></nav>
    );
}
