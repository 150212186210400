import React, { Component } from 'react';
import { Container, Col } from 'react-bootstrap';

import ServerItemList from './ServerItemList'
class Guilds extends Component {
    // Starting state:
    constructor(props) {
        super(props)
        this.state = {
            GuildsPerServer:
                [
                    { Name: "Server", ItemNames: ['Guild Name'] }
                ],
            Error: null,
            HasResults: false
        }
    }

    render() {
        return (
            <Container>
                <Col>
                    <ServerItemList ItemsPerServer={this.state.GuildsPerServer} ItemLinkBase="guild" />
                </Col>
            </Container>
        );
    }
}

export default Guilds;