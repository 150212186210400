import React from 'react';
import { Row, Col, Alert, Container } from 'react-bootstrap';
import Changelog from './Changelog';

export default function Download() {
    return (
        <Container >
            <Row>
                <Col>
                    <Alert>
                        <Changelog />
                    </Alert>
                </Col>
            </Row>
        </Container>
    );
}
