import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useAuth } from 'react-oauth2-pkce';

/*
*   Message displayed after logout, currently includes home component.
*/
export default function Logout() {
    const { authService } = useAuth();
    return (
        <Container>
            <Col>
                {!authService.isAuthenticated() ?
                    <Row>
                        <h3>You have been logged out of the application.</h3>
                    </Row>
                    :
                    <Row>
                        <h3>Click Log out above, to log out.</h3>
                    </Row>
                }
            </Col>
        </Container>
    );
}
