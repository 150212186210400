import Wizard from '../images/class/wizard.png';
import Warlock from '../images/class/warlock.png';
import Cleric from '../images/class/cleric.png';
import Druid from '../images/class/druid.png';
import Barbarian from '../images/class/barbarian.png';
import Paladin from '../images/class/paladin.png';
import Fighter from '../images/class/fighter.png';
import Bard from '../images/class/bard.png';
import Monk from '../images/class/monk.png';
import Sorcerer from '../images/class/sorcerer.png';
import Rogue from '../images/class/rogue.png';
import Ranger from '../images/class/ranger.png';
import FavoredSoul from '../images/class/favoredsoul.png';
import Artificer from '../images/class/artificer.png';
import Alchemist from '../images/class/alchemist.png';

const ClassIcon = (className) => {
    switch (className) {
        default:
        case "Monk":
            return Monk
        case "Bard":
            return Bard
        case "Rogue":
            return Rogue
        case "Ranger":
            return Ranger
        case "Cleric":
            return Cleric
        case "Druid":
            return Druid
        case "Artificer":
            return Artificer
        case "Alchemist":
            return Alchemist
        case "Fighter":
            return Fighter
        case "Favored Soul":
            return FavoredSoul
        case "Warlock":
            return Warlock
        case "Wizard":
            return Wizard
        case "Sorcerer":
            return Sorcerer
        case "Paladin":
            return Paladin
        case "Barbarian":
            return Barbarian;
    }
}

export default ClassIcon;