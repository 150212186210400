import AdventurerStatus from '../images/status/adventurer_status.png';
import ChampionStatus from '../images/status/champion_status.png';
import HeroStatus from '../images/status/hero_status.png';
import LegendStatus from '../images/status/legend_status.png';

const StatusIcon = (buildPointTotal, isDrow) => {
    if (isDrow) {
        switch (parseInt(buildPointTotal)) {
            default:
            case 28:
                return ChampionStatus
            case 30:
                return HeroStatus
            case 32:
                return LegendStatus
        }
    } else {
        switch (parseInt(buildPointTotal)) {
            default:
            case 28:
                return AdventurerStatus
            case 32:
                return ChampionStatus
            case 34:
                return HeroStatus
            case 36:
                return LegendStatus
        }
    }
}

export default StatusIcon;