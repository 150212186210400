const EpicXpToNextLevel = (classLevel) => {
    var epicXpToNextLevel = 0;
    if (classLevel > 10)
        classLevel = 10;
    switch (classLevel) {
        default:
        case 0:
            epicXpToNextLevel = 600000;
            break;
        case 1:
            epicXpToNextLevel = 1250000;
            break;
        case 2:
            epicXpToNextLevel = 1950000;
            break;
        case 3:
            epicXpToNextLevel = 2700000;
            break;
        case 4:
            epicXpToNextLevel = 3500000;
            break;
        case 5:
            epicXpToNextLevel = 4350000;
            break;
        case 6:
            epicXpToNextLevel = 5250000;
            break;
        case 7:
            epicXpToNextLevel = 6200000;
            break;
        case 8:
            epicXpToNextLevel = 7200000;
            break;
        case 9:
            epicXpToNextLevel = 8250000;
            break;
        case 10:
            epicXpToNextLevel = 8250000;
            break;
    }
    return epicXpToNextLevel;
}

export default EpicXpToNextLevel;