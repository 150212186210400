const Servers = [
    { value: 'Argonnessen', label: 'Argonnessen' },
    { value: 'Cannith', label: 'Cannith' },
    { value: 'Ghallanda', label: 'Ghallanda' },
    { value: 'Khyber', label: 'Khyber' },
    { value: 'Orien', label: 'Orien' },
    { value: 'Sarlona', label: 'Sarlona' },
    { value: 'Thelanis', label: 'Thelanis' },
    { value: 'Hardcore', label: 'Hardcore' },
    { value: 'Wayfinder', label: 'Wayfinder' }
]

export default Servers;