import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, ListGroup, ListGroupItem } from 'react-bootstrap';
import ClassIcon from './ClassIcon';

import '../css/ServerItemListItem.css'

/* 
* Generic Listing of Linkable Names, by server.
*
* [ ServerName:
*    Characters: [ CharacterName ]
* ]
*/
function ServerItemList(props) {
    const { ItemsPerServer, ItemLinkBase } = props;
    if (ItemsPerServer !== undefined && ItemsPerServer !== null && ItemsPerServer.length > 0) {
        return (
            <Container>
                <h3 className="ServerItemListHeader">Character Listing:</h3>
                {ItemsPerServer.map((server, n) => {
                    if (server.ItemNames !== undefined && server.ItemNames.length > 0) {
                        return (
                            <ListGroup key={server.Name} className="ServerItemList">
                                <ListGroupItem className={"serverName snapcolor-secondary"}>{server.Name}</ListGroupItem>
                                {server.ItemNames.map((c, i) => {
                                    if (ItemLinkBase !== undefined) {
                                        return (
                                            <Link key={c.Name} to={ItemLinkBase + "/" + server.Name + "/" + c.Name}>
                                                <ListGroupItem className={(i % 2) === 0 ? "ServerItemListItem snapcolor-primary" : "ServerItemListItem snapcolor-secondary"}>
                                                    {c.Name}{c.level ? c.epicLevel ? ": Epic Level " + (parseInt(c.level) + parseInt(c.epicLevel)) : ": Level " + c.level : null} {c.race ? c.race : null} {c.gender ? c.gender : null} {c.classes ? c.classes.map(charClass => {
                                                        return (
                                                            <div key={charClass.name}>
                                                                <img src={ClassIcon(charClass.name)} alt={charClass.name} />
                                                                {charClass.abbreviation} {charClass.levels ? charClass.levels : "1"}
                                                            </div>
                                                        )
                                                    }) : null}
                                                </ListGroupItem>
                                            </Link>
                                        );
                                    } else {
                                        return <ListGroupItem key={c.Name} className={(i % 2) === 0 ? "ServerItemListItem snapcolor-primary" : "ServerItemListItem snapcolor-secondary"}>{c.Name}</ListGroupItem>
                                    }
                                })}
                            </ListGroup>

                        );
                    } else {
                        return null
                    }
                })}
            </Container>
        )
    } else {
        return (<Container><Row>No Server/Character data found.</Row></Container>);
    }
}

export default ServerItemList;