import React from 'react';
import {
    Link
} from "react-router-dom";
import { useAuth } from 'react-oauth2-pkce';
import { Container, ButtonGroup, Button } from 'react-bootstrap';

import ProfileHeader from './ProfileHeader'
import VoKLogo from '../images/vok_logo.png'

/*
*   Top Nav Bar
*/
export default function Nav() {
    const { authService } = useAuth();
    const isAuthenticated = authService.isAuthenticated();
    
    return (
        <Container>
            <div className="headerRow">
                <div className="row">
                    <div className="media d-flex align-items-center voklogo">
                        <img src={VoKLogo}
                            alt="Vault of Kundarak - MyDDO Interface" />
                        <div className="media-body logo-text voklogoText">
                            <h4 className="m-0 text-light">Vault of Kundarak</h4>
                            <p className="font-weight-light mb-0 text-light">MyDDO Interface</p>
                        </div>
                    </div>
                    <ButtonGroup aria-label="Navigation">
                        <nav>
                            <ul>
                                <li>
                                    <Link to="/"><Button variant="dark">Home</Button></Link>
                                </li>
                                {isAuthenticated ?
                                    <li>
                                        <Link to="/dashboard"><Button variant="dark">My Characters</Button></Link>
                                    </li>
                                    : null}
                                <li>
                                    <Link to="/faq"><Button variant="dark">FAQ</Button></Link>
                                </li>
                                <li>
                                    <Link to="/download"><Button variant="dark">Download</Button></Link>
                                </li>
                            </ul>
                        </nav>
                    </ButtonGroup>
                    <div className="profileHeader">
                        <ProfileHeader />
                    </div>
                </div>
            </div>
        </Container>
    );
}