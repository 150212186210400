import React, { Component } from 'react';

import '../css/Examine.css';
import '../css/treasure/copper.css';

import Colorize from './utils/Colorize'

class Examine extends Component {

    render() {
        const { firstName, surname, totalLevel, classes, race, gender, epicLevels, epicDestiny, biography } = this.props.publicData;
        const { buildTitle } = this.props;

        return (
            <div id="examine" className="examinate">
                <div id="copper">
                    <div className="panel-container">
                        <div className="panel-left-sidebar">
                            <div className="panel-left-top-content">
                                <div className="panel-left-top-image">
                                </div>
                            </div>
                            <div className="panel-left-mid-content">
                                <div className="panel-left-sidebar-image">
                                    <div className="panel-left-fangle">
                                    </div>
                                    <div className="panel-left-sidebar-bottom-image">
                                    </div>
                                </div>
                            </div>
                            <div className="panel-left-bottom-content">
                                <div className="panel-left-bottom-image">
                                </div>
                            </div>
                        </div>
                        <div className="panel-middle">
                            <div className="panel-middle-header">
                                <div className="panel-middle-header-image"></div>
                            </div>
                            <div className="panel-middle-body-container">
                                <div className="panel-middle-top-shadow">
                                    <div className="panel-middle-body-content">
                                        <div className="panel-name">{firstName ? firstName : null}{surname ? " " + surname : null}</div>
                                        <div className="panel-level">{totalLevel ? "Total Levels: " + totalLevel : null}</div>
                                        <div className="panel-classes">
                                            {classes ? classes.map((charClass, i) => {
                                                return (
                                                    <div className="panel-class" key={charClass.name}>{i > 0 ? ` / ` : null}{charClass.name} {charClass.levels ? charClass.levels : "1"}</div>
                                                )
                                            }) : null}{buildTitle ? " (" + buildTitle + ")" : null}
                                        </div>
                                        <div className="panel-epicDestiny">{epicDestiny ? epicDestiny : null}{epicLevels ? " " + epicLevels : null}</div>

                                        <div>{gender ? "Gender: " + gender : null}</div>
                                        <div>{race ? "Race: " + race : null}</div>
                                        {biography ? <div>Bio: {biography.split('\\n').map((b, i) => {
                                            return (
                                                <span key={i}>{Colorize(b)}<br /></span>
                                            )
                                        })}</div> : null}
                                    </div>
                                </div>
                            </div>
                            <div className="panel-middle-footer">
                                <div className="panel-middle-footer-image">
                                    <div className="panel-middle-footer-data">
                                        <div className="panel-middle-coinage-value">
                                            {" "}
                                        </div>
                                        <div className="panel-middle-weight">
                                            {" "}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="panel-right-sidebar">
                            <div className="panel-right-top-content">
                                <div className="panel-right-top-image">
                                </div>
                            </div>
                            <div className="panel-right-mid-content">
                                <div className="panel-right-sidebar-image">
                                    <div className="panel-right-fangle">
                                    </div>
                                    <div className="panel-right-sidebar-bottom-image">
                                    </div>
                                </div>
                            </div>
                            <div className="panel-right-bottom-content">
                                <div className="panel-right-bottom-image">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Examine;