import React from 'react'
import {
  Router,
  Switch,
  Route
} from "react-router-dom";
import { useAuth } from 'react-oauth2-pkce';
import '../css/App.css';

import history from "./utils/history";
import PrivateRoute from "./PrivateRoute";
import AuthRoute from './AuthRoute';
import Home from './Home';
import Logout from './Logout';
import Guilds from './Guilds';
import FAQ from './FAQ';
import Download from './Download';
import Nav from './Nav';
import Profile from './Profile';
import Dashboard from './Dashboard';
import Guild from './Guild';
import Character from './Character';
import { Container, Col, Row } from 'react-bootstrap';

export default function App() {
  const { loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Container fluid>
      <Row>
        <Col>
          <Router history={history}>
            <Nav />
            <Row>
              <Switch>
                <Route path="/guilds" component={Guilds} />
                <Route path="/faq" component={FAQ} />
                <Route path="/download" component={Download} />
                <PrivateRoute path="/dashboard" component={Dashboard} authService={useAuth} />
                <PrivateRoute path="/profile" component={Profile} authService={useAuth} />
                <AuthRoute path="/guild/:server/:guild" component={Guild} authService={useAuth} />
                <AuthRoute path="/snapshot/:uniqueName/:character/:server/" component={Character} authService={useAuth} />
                <AuthRoute path="/character/:server/:character/snapshots/:snapshot" component={Character} authService={useAuth} />
                <AuthRoute path="/character/:server/:character" component={Character} authService={useAuth} />
                <AuthRoute path="/logout" component={Logout} authService={useAuth} />
                <AuthRoute path="/" component={Home} />
              </Switch>
            </Row>
          </Router>
        </Col>
      </Row>
    </Container>
  );
}