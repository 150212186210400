
import React from "react";
import { Route } from "react-router-dom";
import { useAuth } from 'react-oauth2-pkce';

// Wrapper makes sure you're logged in before rendering the child component:
const AuthRoute = ({ component: Component, path, ...rest }) => {
  const { authService } = useAuth();
  const render = props =>
    <Component {...props} authService={authService} />;

  return <Route path={path} render={render} {...rest} />;
};

export default AuthRoute;