import React, { Component } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import axios from 'axios';

import '../css/CharacterSheet.css';

import StatusIcon from './StatusIcon';
import ClassIcon from './ClassIcon';
import StatusTitle from './StatusTitle';
import XpToNextLevel from './XpToNextLevel';
import EpicXpToNextLevel from './EpicXpToNextLevel';
import Colorize from './utils/Colorize';
import GetErrorStatus from './utils/GetErrorStatus';
import Examine from './Examine';
import SnapshotList from './SnapshotList';
import CharacterControl from './CharacterControl';
import FeatListing from './FeatListing'

import XpBar from '../images/xp_bar.png';
import EpicXpBar from '../images/xp_bar-epic.png';
import XpBarLevelUp from '../images/xp_bar-levelup.png';
import EpicXpBarLevelUp from '../images/xp_bar-epic-levelup.png';

/*
*   This function will be used to display all of available details for a character, based on Auth/authorization or if public information is available.
*/
class Character extends Component {
    // Starting state:
    constructor(props) {
        super(props)
        this.state = {
            tabId: 0,
            subTabId: 0
        }
    }

    _getPersonalOptions = async () => {
        const { authService } = this.props
        const isAuthenticated = authService.isAuthenticated();
        if (isAuthenticated) {
            let tokens = authService.getAuthTokens()
            return ({
                headers: {
                    'Authorization': `Bearer ${tokens.access_token}`
                }
            })
        } else {
            return ({
                headers: {}
            })
        };
    }

    _loadNewSnapshot() {
        this._getSnapshots(this.state.server, this.state.requestedCharacter)
    }

    _getSnapshots = async (server, character) => {
        var apiEndpoint = process.env.REACT_APP_SERVICE;
        var url = apiEndpoint + '/Character/' + server + '/' + character + "/snapshots";
        let options = await this._getPersonalOptions();
        // use axios to fetch the character from the api:
        //console.log("Contacting API: " + url);
        axios.get(url, options)
            .then(res => {
                const snapshotData = res.data;
                this.setState({ snapshotData }, () => {
                    console.log("Loaded Snapshots: " + this.state.snapshotData.length)
                });
            }).catch(e => {
                this.setState({ errorMessage: GetErrorStatus(e.response.status) },
                    () => console.log("Error message: " + this.state.errorMessage));
            });
    }

    _getCharacterData = async (server, character) => {
        var apiEndpoint = process.env.REACT_APP_SERVICE;
        var url = apiEndpoint + '/Character/' + server + '/' + character;
        let options = await this._getPersonalOptions();
        // use axios to fetch the character from the api:
        //console.log("Contacting API: " + url);
        axios.get(url, options)
            .then(res => {
                const charData = res.data;
                this.setState({ charData, isSnapshot: false, snapshotName: undefined }, async () => {
                    await this._getSnapshots(server, character);
                    console.log("Loaded character: " + this.state.charData.firstName)
                });
            }).catch(e => {
                // check for 404, 500 and respond accordingly:
                if (e.response === undefined) {
                    this.setState({ errorMessage: "Action was canceled." },
                        () => console.log("Error message: " + this.state.errorMessage));
                } else {
                    this.setState({ errorMessage: GetErrorStatus(e.response.status) },
                        () => console.log("Error message: " + this.state.errorMessage));
                }
            });
    }

    _getUniqueSnapshotData = async (server, character, uniqueName) => {
        var apiEndpoint = process.env.REACT_APP_SERVICE;
        var url = apiEndpoint + '/Character/' + server + '/' + character + '/snapshots/p/' + uniqueName;
        let options = await this._getPersonalOptions();
        // use axios to fetch the character from the api:
        //console.log("Contacting API: " + url);
        axios.get(url, options)
            .then(res => {
                const charData = res.data;
                this.setState({ charData, isSnapshot: true, snapshotName: uniqueName }, async () => {
                    await this._getSnapshots(server, character);
                    console.log("Loaded unique snapshot of character: " + this.state.charData.firstName)
                });
            }).catch(e => {
                // check for 404, 500 and respond accordingly:
                if (e.response === undefined) {
                    this.setState({ errorMessage: "Action was canceled." },
                        () => console.log("Error message: " + this.state.errorMessage));
                } else {
                    this.setState({ errorMessage: GetErrorStatus(e.response.status) },
                        () => console.log("Error message: " + this.state.errorMessage));
                }
            });
    }

    _getSnapshotData = async (server, character, snapshot) => {
        var apiEndpoint = process.env.REACT_APP_SERVICE;
        var url = apiEndpoint + '/Character/' + server + '/' + character + '/snapshots/' + snapshot;
        let options = await this._getPersonalOptions();
        // use axios to fetch the character from the api:
        //console.log("Contacting API: " + url);
        axios.get(url, options)
            .then(res => {
                const charData = res.data;
                this.setState({ charData, isSnapshot: true, snapshotName: snapshot }, async () => {
                    await this._getSnapshots(server, character);
                    console.log("Loaded snapshot of character: " + this.state.charData.firstName)
                });
            }).catch(e => {
                // check for 404, 500 and respond accordingly:
                if (e.response === undefined) {
                    this.setState({ errorMessage: "Action was canceled." },
                        () => console.log("Error message: " + this.state.errorMessage));
                } else {
                    this.setState({ errorMessage: GetErrorStatus(e.response.status) },
                        () => console.log("Error message: " + this.state.errorMessage));
                }
            });
    }

    /*
    * 0 = Stats, 1 = Skills, 2 = Feats.. etc 
    */
    _selectTab = (tabId) => {
        this.setState(
            { tabId: tabId, subTabId: 0 }
        );
    }

    /*
    * 0 = Stats, 1 = Additional Stat Details, 2 = Bio 
    * Always sets the tab too 0, until we add more subtab buttons for spells?
    */
    _selectStatSubTab = (subTabId) => {
        this.setState(
            { tabId: 0, subTabId: subTabId }
        );
    }

    mountCharacter() {
        // did we get a prop? use that instead:
        let { character, server } = this.props;
        if (character === undefined && server === undefined) {
            const { server, character, snapshot, uniqueName } = this.props.match.params;
            if ((snapshot !== undefined && snapshot !== null) || (uniqueName !== undefined && uniqueName !== null)) {
                if (uniqueName !== undefined) {
                    this._getUniqueSnapshotData(server, character, uniqueName);
                } else {
                    this._getSnapshotData(server, character, snapshot);
                }
            } else {
                this._getCharacterData(server, character);
            }
            this.setState(
                { server: server, requestedCharacter: character, tabId: 0 }
            );
        } else {
            if (this.props.match !== undefined) {
                const { uniqueName } = this.props.match.params;
                this._getUniqueSnapshotData(server, character, uniqueName);
            }
            this._getCharacterData(server, character);
            this.setState(
                { server: server, requestedCharacter: character, tabId: 0 }
            );
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            this.setState({ errorMessage: false });
            this.mountCharacter();
        }
    }

    componentDidMount() {
        this.mountCharacter();
    }

    _limitBar(v) {
        return v > 252 ? 252 : v < 0 ? 0 : v;
    }

    // tab states are states that represent if a character sheet tab is active, inactive, active hovered, or inactive hovered:


    render() {
        const { requestedCharacter, server, errorMessage, snapshotName, snapshotData, isSnapshot, subTabId, tabId } = this.state;

        if (errorMessage)
            return (<div className="errorMessage">{errorMessage}</div>);
        if (requestedCharacter !== undefined && requestedCharacter !== "") {
            if (this.state.charData !== undefined && this.state.charData !== "Not found") {
                // extract the values from the character data for use in template:
                const {
                    publicData, statData, websiteOptions, isOwner, skillData, featData, uniqueName, snapshotDescription, description
                } = this.state.charData;

                // This is required?
                if (publicData === undefined || publicData === null) {
                    return (<div>{requestedCharacter} has no public Data...</div>)
                }

                const { firstName, surname, totalLevel, classes, race, gender, buildPoints, epicLevels, epicDestiny, biography } = publicData;

                var isDrow = (race === "Drow");
                var buildTitle = StatusTitle(buildPoints, isDrow);

                // If no stat data is present, return an examination panel with the public social info:
                if (statData === undefined || statData === null) {
                    return (
                        <div className="charSheetMini">
                            <div className="charSheetMiniInfo">{requestedCharacter} only shares basic public information on {server}:</div>
                            <Examine publicData={publicData} buildTitle={buildTitle} />
                            <div className="charSheetExtras">
                                {isSnapshot ?
                                    <div>
                                        <h3>Snapshot info:</h3>
                                        <p>
                                            {snapshotName ? snapshotName : null}{description ? ": " + description : null}
                                        </p>
                                    </div>
                                    :
                                    null
                                }
                                {isOwner ?
                                    <div className="charSheetCreatSnapshot">
                                        <CharacterControl character={firstName} server={server} websiteOptions={websiteOptions} loadNewSnapshot={this._loadNewSnapshot.bind(this)} isSnapshot={isSnapshot} snapshotName={snapshotName} uniqueName={uniqueName} /></div>
                                    : null}
                                {snapshotData !== undefined && snapshotData.length > 0 ? <SnapshotList snapshots={snapshotData} character={requestedCharacter} server={server} /> : null}
                                {uniqueName ?
                                    <p className="charSheetPermalink"><a href={"/snapshot/" + uniqueName + "/" + requestedCharacter + "/" + server}>Snapshot Permalink</a></p>
                                    : <p className="charSheetPermalink"><a href={"/character/" + server + "/" + requestedCharacter}>Permalink</a></p>}
                            </div>
                        </div>
                    )
                }

                const { armor, strength, charisma, intelligence, dexterity, constitution, wisdom, hitPoints, spellPoints, fortSave, reflexSave, willSave, baseAttackBonus,
                    fortification, spellResistance, acidResistance, fireResistance, coldResistance, sonicResistance,
                    electricResistance, physicalResistanceRating, magicalResistanceRating, ki, totalHeroicXp, totalEpicXp, alignment,
                    actionPoints, heroicPastLives, positiveHealingAmplification, negativeHealingAmplification, repairHealingAmplification,
                    dodgeChance, maxDodgeBonus, meleePower, rangedPower, rangedAttackSpeedBonus, meleeAttackSpeedBonus, doubleshot, doublestrike, offhandDoublestrike,
                    bypassDeflection, missileDeflection, acidSpellpower, coldSpellpower, fireSpellpower, electricSpellpower, forceSpellpower, lightSpellpower, negativeSpellpower,
                    poisonSpellpower, repairSpellpower, sonicSpellpower, universalSpellpower, positiveSpellpower } = statData;

                // A bit of logic finding:
                var totalArmorClass = 10;
                if (armor.armorBonus !== undefined) {
                    totalArmorClass += armor.armorBonus;
                }
                if (armor.shieldBonus !== undefined) {
                    totalArmorClass += armor.shieldBonus;
                }
                if (armor.deflection !== undefined) {
                    totalArmorClass += armor.deflection;
                }
                if (armor.miscBonus !== undefined) {
                    totalArmorClass += armor.miscBonus;
                }
                if (armor.naturalArmor !== undefined) {
                    totalArmorClass += armor.naturalArmor;
                }
                if (dexterity.modifier !== undefined) {
                    if (armor.maxDexBonus === null || armor.maxDexBonus > dexterity.modifier)
                        totalArmorClass += dexterity.modifier;
                    else
                        totalArmorClass += armor.maxDexBonus;
                }

                // base 2, haven't found any modfiers yet:
                var blockingArmorBonus = 2;

                var dodge = null;

                if (dodgeChance !== undefined) {
                    dodge = parseInt(Math.ceil(dodgeChance * 100))
                }

                var XPTotal = 0;
                var XPToNextLevel = 0;
                var XPToThisLevel = 0;
                var XPToNextLevelLabel = 0;
                var xpwidth = 0;
                var overlevel = false;

                if (totalEpicXp !== undefined && totalEpicXp > 0) {
                    XPToThisLevel = EpicXpToNextLevel(epicLevels)
                    XPToNextLevel = EpicXpToNextLevel(epicLevels)
                    XPTotal = totalEpicXp;
                    XPToNextLevelLabel = XPToThisLevel
                    xpwidth = (Math.abs(XPToThisLevel - XPTotal / XPToNextLevel - XPToThisLevel))
                } else {
                    XPToThisLevel = XpToNextLevel((totalLevel - 1), heroicPastLives)
                    XPToNextLevel = XpToNextLevel(totalLevel, heroicPastLives)
                    XPTotal = totalHeroicXp;
                    XPToNextLevelLabel = XPToNextLevel
                    xpwidth = Math.abs(XPToThisLevel - XPTotal / XPToNextLevel - XPToThisLevel)
                }

                if (XPTotal >= XPToNextLevel) {
                    overlevel = true;
                }

                // Thanks Clemeit!
                //MAX_WIDTH * Max((Current_XP - This_Levels_XP) / (Next_Levels_XP - This_Levels_XP), 1)
                //(90k - 72k) / (112k - 72k) = 18k/30k = 60%
                var barWidth = this._limitBar(252 * xpwidth)

                const xpBarStyle = {
                    width: (
                        barWidth
                    ).toString() + "px",
                    //backgroundImage: "url(" + Background + ")",
                    backgroundImage: totalEpicXp !== undefined && totalEpicXp > 0 ? overlevel ? `url(${EpicXpBarLevelUp})` : `url(${EpicXpBar})` : overlevel ? `url(${XpBarLevelUp})` : `url(${XpBar})`
                }

                var drString = "";

                return (
                    <div className="charSheetWrapper">
                        <div className="charSheet">
                            <div className="charSheetTopTabs">
                                <div className="charSheetTabButtons">
                                    <div className={tabId === 0 ? "charSheetTabButtonStats" : "charSheetTabButtonInactiveStats"} onClick={() => {
                                        this._selectTab(0)
                                        this._selectStatSubTab(0)
                                    }}>
                                        {/* Nothing */}
                                    </div>
                                    <div className={tabId === 1 ? "charSheetTabButtonSkills" : (skillData !== undefined && skillData !== null) ? "charSheetTabButtonInactiveSkills" : "charSheetTabButtonDisabledSkills"}
                                        onClick={() => {
                                            if (skillData !== undefined && skillData !== null) {
                                                this._selectTab(1)
                                            }
                                        }}>
                                        {/* Nothing */}
                                    </div>
                                    <div className={tabId === 2 ? "charSheetTabButtonFeats" : (featData !== undefined && featData !== null) ? "charSheetTabButtonInactiveFeats" : "charSheetTabButtonDisabledFeats"}
                                        onClick={() => {
                                            if (featData !== undefined && featData !== null)
                                                this._selectTab(2)
                                        }}>
                                        {/* Nothing */}
                                    </div>
                                </div>
                            </div>
                            <div className="charSheetMiddleBody">
                                <div className="charSheetLeftBar">
                                    {/* Nothing */}
                                </div>
                                {tabId === 2 && featData ?
                                    <FeatListing featData={featData} />
                                    : null}
                                {tabId === 1 && skillData ?
                                    <div id="charSheetSkillsTab">
                                        <div className="charSheetSkillsText">
                                            {skillData.skills.map((s, i) => {
                                                var miscMod = s.value - s.base - s.abilityMod;
                                                return (
                                                    <div className="charSheetSkill">
                                                        <div className="charSheetSkillIcon">
                                                            <img alt={s.name} src={`https://content.vaultofkundarak.com/icons/${s.iconId.toString(16).toUpperCase()}.png`} />
                                                        </div>
                                                        <div className="charSheetSkillName">{s.name}</div>
                                                        <div className="charSheetSkillKey">{s.relevantAbility}</div>
                                                        <div className="charSheetSkillTotal">{s.usableUntrained ? s.value : s.base > 0 ? s.value : "n/a"}</div>
                                                        <div className="charSheetSkillRank">{s.usableUntrained ? s.base : s.base > 0 ? s.base : "n/a"}</div>
                                                        <div className="charSheetSkillAbilityMod">{s.usableUntrained ?
                                                            s.abilityMod >= 0 ? "+" + s.abilityMod : s.abilityMod
                                                            : s.base > 0 ?
                                                                s.abilityMod >= 0 ? "+" + s.abilityMod : s.abilityMod
                                                                : "n/a"}
                                                        </div>
                                                        <div className="charSheetSkillMiscMod">{s.usableUntrained ?
                                                            miscMod >= 0 ? "+" + miscMod : miscMod
                                                            : s.base > 0 ?
                                                                miscMod >= 0 ? "+" + miscMod : miscMod
                                                                : "n/a"}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    : null}
                                {tabId === 0 && subTabId === 2 ?
                                    <div id="charSheetBio">
                                        <div className="charSheetBioText">
                                            {biography ? <div>Bio: {biography.split('\\n').map((b, i) => {
                                                return (
                                                    <span key={i}>{Colorize(b)}<br /></span>
                                                )
                                            })}</div> : null}
                                        </div>
                                    </div>
                                    : null}
                                {tabId === 0 && subTabId === 1 ?
                                    <div id="charSheetDetails">
                                        <div className="charSheetDetailText">
                                            This tab is still being created. Please wait until a later deployment phase to use this tab.
                                        </div>
                                    </div>
                                    : null}
                                {tabId === 0 && subTabId === 0 ?
                                    <div id="charSheetBodyContents">
                                        <div className="charSheetName">
                                            <OverlayTrigger
                                                key={requestedCharacter}
                                                placement="right"
                                                overlay={
                                                    <Tooltip id={`tooltip-${requestedCharacter}`}>
                                                        {buildPoints ? <span><strong>{buildTitle}:</strong> {buildPoints} Point Build<br /></span> : 0}
                                                        {firstName} {surname ? surname : null} of {server}<br />
                                                        Level {totalLevel}<br />
                                                        {epicLevels ? "Epic Level: " + epicLevels : null}<br />
                                                        {epicDestiny ? epicDestiny : null}
                                                    </Tooltip>
                                                }
                                            >
                                                <img className="statusIcon" src={StatusIcon(buildPoints, isDrow)} alt={buildTitle} />
                                            </OverlayTrigger>
                                            {firstName} {surname ? surname : null}
                                        </div>
                                        <div className="charSheetClass">
                                            {classes ? classes.map(charClass => {
                                                return (
                                                    <div key={charClass.name}>
                                                        <img src={ClassIcon(charClass.name)} alt={charClass.name} />
                                                        {charClass.abbreviation} {charClass.levels ? charClass.levels : "1"}
                                                    </div>
                                                )
                                            }) : null}

                                        </div>
                                        <div className="charSheetRaceAlignmentGender">
                                            <div className="charSheetRace">{race ? race : null}</div>
                                            <div className="charSheetAlignment">{alignment ? alignment : null}</div>
                                            <div className="charSheetGender">{gender ? gender : null}</div>
                                        </div>
                                        <div className="charSheetXpActionPoints">
                                            <div className="charSheetXp">
                                                <div className="charSheetXpBackground" style={xpBarStyle}>
                                                    {" "}
                                                </div>
                                                <div className="charSheetXpTextValue">
                                                    {XPTotal ? XPTotal.toLocaleString() + " / " + XPToNextLevelLabel.toLocaleString() : "0 / 4000"}
                                                </div>
                                            </div>
                                            <div className="charSheetActionPoints">{actionPoints ? actionPoints : null}</div>
                                        </div>
                                        <div className="charSheetAbiltityStats">
                                            <OverlayTrigger
                                                key="STR"
                                                placement="right"
                                                overlay={
                                                    <Tooltip id="tooltip-strength">
                                                        <strong>STRENGTH:</strong><br />
                                                        {strength.racialBase ? <span><strong>Base</strong>: {strength.racialBase}<br /></span> : null}
                                                        {strength.inherentBonus ? <span><strong>Inherent Bonus</strong>: +{strength.inherentBonus}<br /></span> : null}
                                                        {strength.advancement ? <span><strong>Advancement</strong>: +{strength.advancement}<br /></span> : null}
                                                        {strength.featBonus ? <span><strong>Feat Bonus</strong>: +{strength.featBonus}<br /></span> : null}
                                                        {strength.effectBonus ? <span><strong>Item and Effects</strong>: +{strength.effectBonus}<br /></span> : null}
                                                    </Tooltip>
                                                }
                                            >
                                                <div className="charSheetSTR">{strength.value}</div>
                                            </OverlayTrigger>
                                            <OverlayTrigger
                                                key="DEX"
                                                placement="right"
                                                overlay={
                                                    <Tooltip id="tooltip-dexterity">
                                                        <strong>DEXTERITY:</strong><br />
                                                        {dexterity.racialBase ? <span><strong>Base</strong>: {dexterity.racialBase}<br /></span> : null}
                                                        {dexterity.inherentBonus ? <span><strong>Inherent Bonus</strong>: +{dexterity.inherentBonus}<br /></span> : null}
                                                        {dexterity.advancement ? <span><strong>Advancement</strong>: +{dexterity.advancement}<br /></span> : null}
                                                        {dexterity.featBonus ? <span><strong>Feat Bonus</strong>: +{dexterity.featBonus}<br /></span> : null}
                                                        {dexterity.effectBonus ? <span><strong>Item and Effects</strong>: +{dexterity.effectBonus}<br /></span> : null}
                                                    </Tooltip>
                                                }
                                            >
                                                <div className="charSheetDEX">{dexterity.value}</div>
                                            </OverlayTrigger>
                                            <OverlayTrigger
                                                key="CON"
                                                placement="right"
                                                overlay={
                                                    <Tooltip id="tooltip-constitution">
                                                        <strong>CONSTITUTION:</strong><br />
                                                        {constitution.racialBase ? <span><strong>Base</strong>: {constitution.racialBase}<br /></span> : null}
                                                        {constitution.inherentBonus ? <span><strong>Inherent Bonus</strong>: +{constitution.inherentBonus}<br /></span> : null}
                                                        {constitution.advancement ? <span><strong>Advancement</strong>: +{constitution.advancement}<br /></span> : null}
                                                        {constitution.featBonus ? <span><strong>Feat Bonus</strong>: +{constitution.featBonus}<br /></span> : null}
                                                        {constitution.effectBonus ? <span><strong>Item and Effects</strong>: +{constitution.effectBonus}<br /></span> : null}
                                                    </Tooltip>
                                                }
                                            >
                                                <div className="charSheetCON">{constitution.value}</div>
                                            </OverlayTrigger>
                                            <OverlayTrigger
                                                key="INT"
                                                placement="right"
                                                overlay={
                                                    <Tooltip id="tooltip-intelligence">
                                                        <strong>INTELLIGENCE:</strong><br />
                                                        {intelligence.racialBase ? <span><strong>Base</strong>: {intelligence.racialBase}<br /></span> : null}
                                                        {intelligence.inherentBonus ? <span><strong>Inherent Bonus</strong>: +{intelligence.inherentBonus}<br /></span> : null}
                                                        {intelligence.advancement ? <span><strong>Advancement</strong>: +{intelligence.advancement}<br /></span> : null}
                                                        {intelligence.featBonus ? <span><strong>Feat Bonus</strong>: +{intelligence.featBonus}<br /></span> : null}
                                                        {intelligence.effectBonus ? <span><strong>Item and Effects</strong>: +{intelligence.effectBonus}<br /></span> : null}
                                                    </Tooltip>
                                                }
                                            >
                                                <div className="charSheetINT">{intelligence.value}</div>
                                            </OverlayTrigger>
                                            <OverlayTrigger
                                                key="WIS"
                                                placement="right"
                                                overlay={
                                                    <Tooltip id="tooltip-wisdom">
                                                        <strong>WISDOM:</strong><br />
                                                        {wisdom.racialBase ? <span><strong>Base</strong>: {wisdom.racialBase}<br /></span> : null}
                                                        {wisdom.inherentBonus ? <span><strong>Inherent Bonus</strong>: +{wisdom.inherentBonus}<br /></span> : null}
                                                        {wisdom.advancement ? <span><strong>Advancement</strong>: +{wisdom.advancement}<br /></span> : null}
                                                        {wisdom.featBonus ? <span><strong>Feat Bonus</strong>: +{wisdom.featBonus}<br /></span> : null}
                                                        {wisdom.effectBonus ? <span><strong>Item and Effects</strong>: +{wisdom.effectBonus}<br /></span> : null}
                                                    </Tooltip>
                                                }
                                            >
                                                <div className="charSheetWIS">{wisdom.value}</div>
                                            </OverlayTrigger>
                                            <OverlayTrigger
                                                key="CHA"
                                                placement="right"
                                                overlay={
                                                    <Tooltip id="tooltip-charisma">
                                                        <strong>CHARISMA:</strong><br />
                                                        {charisma.racialBase ? <span><strong>Base</strong>: {charisma.racialBase}<br /></span> : null}
                                                        {charisma.inherentBonus ? <span><strong>Inherent Bonus</strong>: +{charisma.inherentBonus}<br /></span> : null}
                                                        {charisma.advancement ? <span><strong>Advancement</strong>: +{charisma.advancement}<br /></span> : null}
                                                        {charisma.featBonus ? <span><strong>Feat Bonus</strong>: +{charisma.featBonus}<br /></span> : null}
                                                        {charisma.effectBonus ? <span><strong>Item and Effects</strong>: +{charisma.effectBonus}<br /></span> : null}
                                                    </Tooltip>
                                                }
                                            >
                                                <div className="charSheetCHA">{charisma.value}</div>
                                            </OverlayTrigger>
                                        </div>
                                        <div className="charSheetMods">
                                            <div className="charSheetSTRMod">
                                                {strength.modifier ? <span>{strength.modifier > 0 ? "+" + strength.modifier : strength.modifier}</span> : "+0"}
                                            </div>
                                            <div className="charSheetDEXMod">
                                                {dexterity.modifier ? <span>{dexterity.modifier > 0 ? "+" + dexterity.modifier : dexterity.modifier}</span> : "+0"}
                                            </div>
                                            <div className="charSheetCONMod">
                                                {constitution.modifier ? <span>{constitution.modifier > 0 ? "+" + constitution.modifier : constitution.modifier}</span> : "+0"}
                                            </div>
                                            <div className="charSheetINTMod">
                                                {intelligence.modifier ? <span>{intelligence.modifier > 0 ? "+" + intelligence.modifier : intelligence.modifier}</span> : "+0"}
                                            </div>
                                            <div className="charSheetWISMod">
                                                {wisdom.modifier ? <span>{wisdom.modifier > 0 ? "+" + wisdom.modifier : wisdom.modifier}</span> : "+0"}
                                            </div>
                                            <div className="charSheetCHAMod">
                                                {charisma.modifier ? <span>{charisma.modifier > 0 ? "+" + charisma.modifier : charisma.modifier}</span> : "+0"}
                                            </div>
                                        </div>
                                        <div className="charSheetDerivedStats">
                                            <OverlayTrigger
                                                key="HP"
                                                placement="right"
                                                overlay={
                                                    <Tooltip id="tooltip-hitpoints">
                                                        <strong>HITPOINTS:</strong><br />
                                                        {hitPoints.maxHitPointsBase ? <span>{hitPoints.maxHitPointsBase} <strong>Base</strong><br /></span> : null}
                                                        {hitPoints.hitPointsFromConstitution ? <span>+{hitPoints.hitPointsFromConstitution} <strong>Constitution Bonus</strong><br /></span> : null}
                                                        {hitPoints.maxHitPointsFeat ? <span>+{hitPoints.maxHitPointsFeat} <strong>Feat Bonus</strong><br /></span> : null}
                                                        {hitPoints.hitPointsShared ? <span>= {hitPoints.hitPointsShared} <strong>Total</strong><br /></span> : null}
                                                        <strong>HEALING AMPLIFICATION:</strong><br />
                                                        <span>Positive: <strong>{positiveHealingAmplification ? positiveHealingAmplification : 0}</strong><br /></span>
                                                        <span>Negative: <strong>{negativeHealingAmplification ? negativeHealingAmplification : 0}</strong><br /></span>
                                                        <span>Repair: <strong>{repairHealingAmplification ? repairHealingAmplification : 0}</strong><br /></span>
                                                    </Tooltip>
                                                }
                                            >
                                                <div className="charSheetHP">{hitPoints.current}/{hitPoints.max}</div>
                                            </OverlayTrigger>
                                            <div className="charSheetSpells">
                                                <OverlayTrigger
                                                    key="SP"
                                                    placement="right"
                                                    overlay={
                                                        <Tooltip id="tooltip-spellpoints">
                                                            <strong>SPELLPOINTS:</strong><br />
                                                            {spellPoints.maxSpellPointsBase ? <span>{spellPoints.maxSpellPointsBase} <strong>Base</strong><br /></span> : null}
                                                            {spellPoints.spellPointsFromAbilityBonus ? <span>+{spellPoints.spellPointsFromAbilityBonus} <strong>Ability Bonus</strong><br /></span> : null}
                                                            {spellPoints.maxSpellPointsFeat ? <span>+{spellPoints.maxSpellPointsFeat} <strong>Feat Bonus</strong><br /></span> : null}
                                                            {spellPoints.maxSpellPointsEffect ? <span>+{spellPoints.maxSpellPointsEffect} <strong>Enchanted Bonus</strong><br /></span> : null}
                                                            {spellPoints.max ? <span>={spellPoints.max} <strong>Total</strong><br /></span> : null}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <div className="charSheetSP">{spellPoints ? spellPoints.current + "/" + spellPoints.max : "0/0"}</div>
                                                </OverlayTrigger>
                                                <OverlayTrigger
                                                    key="SS"
                                                    placement="right"
                                                    overlay={
                                                        <Tooltip id="tooltip-spellsources">
                                                            <strong>SPELLPOWER:</strong><br />
                                                            {acidSpellpower.spellpower !== undefined ? <span>Acid: {acidSpellpower.spellpower}<br /></span> : null}
                                                            {coldSpellpower.spellpower !== undefined  ? <span>Cold: {coldSpellpower.spellpower}<br /></span> : null}
                                                            {electricSpellpower.spellpower !== undefined  ? <span>Electric: {electricSpellpower.spellpower}<br /></span> : null}
                                                            {fireSpellpower.spellpower !== undefined  ? <span>Fire: {fireSpellpower.spellpower}<br /></span> : null}
                                                            {forceSpellpower.spellpower !== undefined  ? <span>Force: {forceSpellpower.spellpower}<br /></span> : null}
                                                            {lightSpellpower.spellpower !== undefined  ? <span>Light: {lightSpellpower.spellpower}<br /></span> : null}
                                                            {negativeSpellpower.spellpower !== undefined  ? <span>Negative: {negativeSpellpower.spellpower}<br /></span> : null}
                                                            {poisonSpellpower.spellpower !== undefined  ? <span>Poison: {poisonSpellpower.spellpower}<br /></span> : null}
                                                            {positiveSpellpower.spellpower !== undefined  ? <span>Positive: {positiveSpellpower.spellpower}<br /></span> : null}
                                                            {repairSpellpower.spellpower !== undefined  ? <span>Repair: {repairSpellpower.spellpower}<br /></span> : null}
                                                            {sonicSpellpower.spellpower !== undefined  ? <span>Sonic: {sonicSpellpower.spellpower}<br /></span> : null}
                                                            {universalSpellpower.spellpower !== undefined  ? <span>Universal: {universalSpellpower.spellpower}<br /></span> : null}
                                                            <strong>SPELL CRITICAL CHANCE:</strong><br />
                                                            {acidSpellpower.criticalChance !== undefined ? <span>Acid: {Math.floor(acidSpellpower.criticalChance * 100)}<br /></span> : null}
                                                            {coldSpellpower.criticalChance !== undefined ? <span>Cold: {Math.floor(coldSpellpower.criticalChance * 100)}<br /></span> : null}
                                                            {electricSpellpower.criticalChance !== undefined ? <span>Electric: {Math.floor(electricSpellpower.criticalChance * 100)}<br /></span> : null}
                                                            {fireSpellpower.criticalChance !== undefined ? <span>Fire: {Math.floor(fireSpellpower.criticalChance * 100)}<br /></span> : null}
                                                            {forceSpellpower.criticalChance !== undefined ? <span>Force: {Math.floor(forceSpellpower.criticalChance * 100)}<br /></span> : null}
                                                            {lightSpellpower.criticalChance !== undefined ? <span>Light: {Math.floor(lightSpellpower.criticalChance * 100)}<br /></span> : null}
                                                            {negativeSpellpower.criticalChance !== undefined ? <span>Negative: {Math.floor(negativeSpellpower.criticalChance * 100)}<br /></span> : null}
                                                            {poisonSpellpower.criticalChance !== undefined ? <span>Poison: {Math.floor(poisonSpellpower.criticalChance * 100)}<br /></span> : null}
                                                            {positiveSpellpower.criticalChance !== undefined ? <span>Positive: {Math.floor(positiveSpellpower.criticalChance * 100)}<br /></span> : null}
                                                            {repairSpellpower.criticalChance !== undefined ? <span>Repair: {Math.floor(repairSpellpower.criticalChance * 100)}<br /></span> : null}
                                                            {sonicSpellpower.criticalChance !== undefined ? <span>Sonic: {Math.floor(sonicSpellpower.criticalChance * 100)}<br /></span> : null}
                                                            {universalSpellpower.criticalChance !== undefined ? <span>Universal: {Math.floor(universalSpellpower.criticalChance * 100)}<br /></span> : null}
                                                            <strong>SPELL CRITICAL MULTIPLIER:</strong><br />
                                                            <span>Acid: {acidSpellpower.criticalMultiplierMod ? acidSpellpower.criticalMultiplierMod : 0}<br /></span>
                                                            <span>Cold: {coldSpellpower.criticalMultiplierMod ? coldSpellpower.criticalMultiplierMod : 0}<br /></span>
                                                            <span>Electric: {electricSpellpower.criticalMultiplierMod ? electricSpellpower.criticalMultiplierMod : 0}<br /></span>
                                                            <span>Fire: {fireSpellpower.criticalMultiplierMod ? fireSpellpower.criticalMultiplierMod : 0}<br /></span>
                                                            <span>Force: {forceSpellpower.criticalMultiplierMod ? forceSpellpower.criticalMultiplierMod : 0}<br /></span>
                                                            <span>Light: {lightSpellpower.criticalMultiplierMod ? lightSpellpower.criticalMultiplierMod : 0}<br /></span>
                                                            <span>Negative: {negativeSpellpower.criticalMultiplierMod ? negativeSpellpower.criticalMultiplierMod : 0}<br /></span>
                                                            <span>Poison: {poisonSpellpower.criticalMultiplierMod ? poisonSpellpower.criticalMultiplierMod : 0}<br /></span>
                                                            <span>Positive: {positiveSpellpower.criticalMultiplierMod ? positiveSpellpower.criticalMultiplierMod : 0}<br /></span>
                                                            <span>Repair: {repairSpellpower.criticalMultiplierMod ? repairSpellpower.criticalMultiplierMod : 0}<br /></span>
                                                            <span>Sonic: {sonicSpellpower.criticalMultiplierMod ? sonicSpellpower.criticalMultiplierMod : 0}<br /></span>
                                                            <span>Universal: {universalSpellpower.criticalMultiplierMod ? universalSpellpower.criticalMultiplierMod : 0}<br /></span>
                                                        </Tooltip>
                                                    }
                                                >
                                                    <div className="charSheetSpellSource">{" "}</div>
                                                </OverlayTrigger>
                                            </div>
                                            <OverlayTrigger
                                                key="KI"
                                                placement="right"
                                                overlay={
                                                    <Tooltip id="tooltip-ki">
                                                        <strong>KI:</strong><br />
                                                        {ki.maxKiBase ? <span>Base {ki.maxKiBase}<br /></span> : null}
                                                        {ki.maxKiFeat ? <span>Feat {ki.maxKiFeat}<br /></span> : null}
                                                        {ki.abilityBonus ? <span>Ability {ki.abilityBonus}<br /></span> : null}
                                                        {ki.maxKi ? <span>= Total {ki.maxKi}<br /></span> : <span>= Total 0<br /></span>}
                                                    </Tooltip>
                                                }
                                            >
                                                <div className="charSheetKI">{ki.maxKi ? ki.ki ? ki.ki : "~/" + ki.maxKi : "0/0"}</div>
                                            </OverlayTrigger>
                                            <div className="charSheetArmor">
                                                <OverlayTrigger
                                                    key="AC"
                                                    placement="right"
                                                    overlay={
                                                        <Tooltip id="tooltip-ac">
                                                            <strong>ARMOR CLASS:</strong><br />
                                                            {armor.base ? <span>{armor.base} Base Value<br /></span> : null}
                                                            {dexterity.modifier ? <span>+ {armor.maxDexBonus !== undefined || armor.maxDexBonus > dexterity.modifier ? dexterity.modifier : armor.maxDexBonus} Dexterity Bonus <br /></span> : null}
                                                            {armor.maxDexBonus && armor.maxDexBonus !== 99 ? <span className="tooltip-inner-sub">Max Dex Bonus: {armor.maxDexBonus} <br /></span> : null}
                                                            {armor.armorBonus ? <span>+ {armor.armorBonus} Armor Bonus<br /></span> : null}
                                                            {armor.shieldBonus ? <span>+ {armor.shieldBonus} Shield Bonus<br /></span> : null}
                                                            {armor.naturalArmor ? <span>+ {armor.naturalArmor} Natrual Armor Bonus<br /></span> : null}
                                                            {armor.deflection ? <span>+ {armor.deflection} Deflection Bonus<br /></span> : null}
                                                            {armor.miscBonus ? <span>+ {armor.miscBonus} Misc Bonus<br /></span> : null}
                                                            {totalArmorClass ? <span>= Total {totalArmorClass}<br /></span> : null}
                                                            <strong>DODGE CHANCE:</strong><br />
                                                            {dodgeChance ? <span>{dodge}% to dodge<br /></span> : null}
                                                            {maxDodgeBonus ? <span>Dodge chance max: {dodge + maxDodgeBonus}%<br /></span> : null}
                                                            <span>Missle Deflection: {missileDeflection ? missileDeflection : 0}%<br /></span>
                                                        </Tooltip>
                                                    }
                                                >
                                                    <div className="charSheetAC">{totalArmorClass ? totalArmorClass : 0}</div>
                                                </OverlayTrigger>
                                                <OverlayTrigger
                                                    key="ABAB"
                                                    placement="right"
                                                    overlay={
                                                        <Tooltip id="tooltip-blocking">
                                                            <strong>BLOCKING ARMOR BONUS:</strong><br />
                                                            {blockingArmorBonus ? <span>{blockingArmorBonus} Base<br /></span> : null}
                                                            {blockingArmorBonus ? <span>= {blockingArmorBonus} Total<br /></span> : null}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <div className="charSheetBlock">{blockingArmorBonus ? "+" + blockingArmorBonus : "+2"}</div>
                                                </OverlayTrigger>
                                            </div>
                                            <OverlayTrigger
                                                key="SAVE"
                                                placement="right"
                                                overlay={
                                                    <Tooltip id="tooltip-save">
                                                        <strong>SAVE:</strong><br />
                                                        {fortSave.base ? <span>{fortSave.base} Base<br /></span> : null}
                                                        {constitution.modifier ? <span>{constitution.modifier > 0 ? "+" + constitution.modifier: constitution.modifier} Constitution Mod<br /></span> : null}
                                                        {fortSave.featBonus ? <span>+ {fortSave.featBonus} Feat Bonus<br /></span> : null}
                                                        {fortSave.effectBonus ? <span>+ {fortSave.effectBonus} Enchanted Bonus<br /></span> : null}
                                                        {fortSave.totalBonus ? <span>= Total {fortSave.totalBonus}<br /></span> : null}
                                                    </Tooltip>
                                                }
                                            >
                                                <div className="charSheetSF">{fortSave ? (fortSave.totalBonus > 0 ? "+" : null) + fortSave.totalBonus : null}</div>
                                            </OverlayTrigger>
                                            <OverlayTrigger
                                                key="REFLEX"
                                                placement="right"
                                                overlay={
                                                    <Tooltip id="tooltip-reflex">
                                                        <strong>SAVE:</strong><br />
                                                        {reflexSave.base ? <span>{reflexSave.base} Base<br /></span> : null}
                                                        {dexterity.modifier ? <span>{dexterity.modifier > 0 ? "+" + dexterity.modifier: dexterity.modifier} Dexterity Mod<br /></span> : null}
                                                        {reflexSave.featBonus ? <span>+ {reflexSave.featBonus} Feat Bonus<br /></span> : null}
                                                        {reflexSave.effectBonus ? <span>+ {reflexSave.effectBonus} Enchanted Bonus<br /></span> : null}
                                                        {reflexSave.totalBonus ? <span>= Total {reflexSave.totalBonus}<br /></span> : null}
                                                    </Tooltip>
                                                }
                                            >
                                                <div className="charSheetReflex">{reflexSave ? (reflexSave.totalBonus > 0 ? "+" : null) + reflexSave.totalBonus : null}</div>
                                            </OverlayTrigger>
                                            <OverlayTrigger
                                                key="WILL"
                                                placement="right"
                                                overlay={
                                                    <Tooltip id="tooltip-will">
                                                        <strong>SAVE:</strong><br />
                                                        {willSave.base ? <span>{willSave.base} Base<br /></span> : null}
                                                        {wisdom.modifier ? <span>{wisdom.modifier > 0 ? "+" + wisdom.modifier: wisdom.modifier} Wisdom Mod<br /></span> : null}
                                                        {willSave.featBonus ? <span>+ {willSave.featBonus} Feat Bonus<br /></span> : null}
                                                        {willSave.effectBonus ? <span>+ {willSave.effectBonus} Enchanted Bonus<br /></span> : null}
                                                        {willSave.totalBonus ? <span>= Total {willSave.totalBonus}<br /></span> : null}
                                                    </Tooltip>
                                                }
                                            >
                                                <div className="charSheetWill">{willSave ? (willSave.totalBonus > 0 ? "+" : null) + willSave.totalBonus : null}</div>
                                            </OverlayTrigger>
                                            <div className="charSheetAttack">
                                                <div className="charSheetBAB">{baseAttackBonus ? "+" + baseAttackBonus : null}</div>
                                                <OverlayTrigger
                                                    key="ATTACK"
                                                    placement="right"
                                                    overlay={
                                                        <Tooltip id="tooltip-attack">
                                                            <strong>ATTACK BONUSES:</strong><br />
                                                            {doublestrike ? <span>Doublestrike: {(doublestrike * 100).toFixed(2)}%<br /></span> : null}
                                                            {offhandDoublestrike ? <span>Offhand Doublestrike: {(offhandDoublestrike * 100).toFixed(2)}%<br /></span> : null}
                                                            {doubleshot ? <span>Doubleshot: {(doubleshot * 100).toFixed(2)}%<br /></span> : null}
                                                            {meleeAttackSpeedBonus ? <span>Melee Attack Speed Bonus: {meleeAttackSpeedBonus}%<br /></span> : null}
                                                            {rangedAttackSpeedBonus ? <span>Ranged Attack Speed Bonus: {rangedAttackSpeedBonus}%<br /></span> : null}
                                                            {bypassDeflection ? <span>Bypass Deflection: {bypassDeflection}%<br /></span> : null}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <div className="charSheetAttackBonus">{" "}</div>
                                                </OverlayTrigger>
                                                <OverlayTrigger
                                                    key="WEAPON"
                                                    placement="right"
                                                    overlay={
                                                        <Tooltip id="tooltip-weapon">
                                                            <strong>WEAPON POWER:</strong><br />
                                                            <span> Melee Weapon Power: {meleePower ? meleePower : 0}%<br /></span>
                                                            <span> Ranged Weapon Power: {rangedPower ? rangedPower : 0}%<br /></span>
                                                        </Tooltip>
                                                    }
                                                >
                                                    <div className="charSheetWeaponPower">{" "}</div>
                                                </OverlayTrigger>
                                            </div>
                                            <div className="charSheetSpellFortitude">{spellResistance ? spellResistance : "0"}</div>
                                            <div className="charSheetFortifictation">{fortification ? parseInt(Math.ceil(fortification * 100)) + "%" : "0"}</div>
                                        </div>
                                        <div className="charSheetDamageReduction">
                                            <span>{drString}</span>
                                        </div>
                                        <div className="charSheetResistances">
                                            <div className="charSheetAcid">{acidResistance ? acidResistance : 0}</div>
                                            <div className="charSheetCold">{coldResistance ? coldResistance : 0}</div>
                                            <div className="charSheetElectric">{electricResistance ? electricResistance : 0}</div>
                                            <div className="charSheetFire">{fireResistance ? fireResistance : 0}</div>
                                            <div className="charSheetSonic">{sonicResistance ? sonicResistance : 0}</div>
                                            <OverlayTrigger
                                                key="PHYSICAL"
                                                placement="right"
                                                overlay={
                                                    <Tooltip id="tooltip-physical">
                                                        {physicalResistanceRating ? <span>Physical Resistance Rating: {(100 - (100 / (100 + physicalResistanceRating) * 100)).toFixed(2)}%<br /></span> : 0}
                                                    </Tooltip>
                                                }
                                            >
                                                <div className="charSheetPhysical">{physicalResistanceRating ? physicalResistanceRating : 0}</div>
                                            </OverlayTrigger>
                                            <OverlayTrigger
                                                key="MAGICAL"
                                                placement="right"
                                                overlay={
                                                    <Tooltip id="tooltip-magical">
                                                        {magicalResistanceRating ? <span>Magical Resistance Rating: {(100 - (100 / (100 + magicalResistanceRating) * 100)).toFixed(2)}%<br /></span> : 0}
                                                    </Tooltip>
                                                }
                                            >
                                                <div className="charSheetMagical">{magicalResistanceRating ? magicalResistanceRating : 0}</div>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                    : null}
                                <div className="charSheetRightBar">
                                    {tabId === 0 ?
                                        <div className="charSheetStatButtons">
                                            <div className={subTabId === 0 ? "charSheetStatButtonMain" : "charSheetStatButtonMainInactive"} onClick={() => this._selectStatSubTab(0)}>
                                            </div>
                                            <div className={subTabId === 1 ? "charSheetStatButtonDetails" : "charSheetStatButtonDetailsInactive"} onClick={() => this._selectStatSubTab(0)}>
                                            </div>
                                            <div className={subTabId === 2 ? "charSheetStatButtonBio" : "charSheetStatButtonBioInactive"} onClick={() => this._selectStatSubTab(2)}>
                                            </div>
                                        </div>
                                        : null}
                                </div>
                            </div>
                            <div className="charSheetBottomFooter">
                                {/* Nothing */}
                            </div>
                        </div>
                        <div className="charSheetExtras">
                            {isSnapshot ?
                                <div>
                                    <h3>Snapshot info:</h3>
                                    <p>
                                        {snapshotName ? snapshotName : null}{description ? ": " + description : null}
                                    </p>
                                </div>
                                :
                                null
                            }
                            {isOwner ?
                                <div className="charSheetCreateSnapshot">
                                    <CharacterControl character={firstName} server={server} websiteOptions={websiteOptions} loadNewSnapshot={this._loadNewSnapshot.bind(this)} isSnapshot={isSnapshot} snapshotName={snapshotName} uniqueName={uniqueName} authService={this.props.authService} /></div>
                                : null}
                            {snapshotData !== undefined && snapshotData.length > 0 ?
                                <SnapshotList snapshots={snapshotData} character={firstName} server={server} snapshotDescription={snapshotDescription} />
                                : null
                            }
                            {uniqueName ?
                                <p className="charSheetPermalink"><a href={"/snapshot/" + uniqueName + "/" + requestedCharacter + "/" + server}>Snapshot Permalink</a></p>
                                : <p className="charSheetPermalink"><a href={"/character/" + server + "/" + requestedCharacter}>Permalink</a></p>}
                        </div>
                    </div>
                );
            } else if (this.state.charData === "Not found") {
                return (<div className="notFound">Could not find character!</div>)
            } else {
                return (<div className="loadingMessage">Loading...</div>)
            }
        } else {
            return (<div>Character not found?!</div>)
        }
    }
}

export default Character;