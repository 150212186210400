import React, { Component } from 'react';
import { Col, Row, Container } from 'react-bootstrap';

import '../css/FAQ.css';

class FAQ extends Component {
    render() {
        return (
            <Container>
                <Row>
                    <Col className="faqContainer">
                        <div><h3>Frequently Asked Questions:</h3></div>
                        <details open>
                            <summary>A Guide to MyDDO</summary>
                            <div className="faq__content" id="guide">
                                <ol>
                                    <li>Download and install <a href="https://dungeonhelper.com/download">Dungeon Helper</a>.</li>
                                    <li>Download MyDDO from <a href="/download">here</a>.</li>
                                    <li>Launch the game. You will see a bar at the top of your game. (It fades out if the game is not the current active window).</li>
                                    <li>Click on the round sheet icon to the right side of that bar. It toggles the MyDDO interface.</li>
                                    <li>You have two possibilities here: make your data public or private.</li>
                                    <ul>
                                        <li>Public - Just choose the data you like to upload.
                                            <ul>
                                                <li>Green button: data will be uploaded and public</li>
                                                <li>X-button: this data WON'T be uploaded</li>
                                                <li>Click upload, check out MyDDO page now and search for your character name on the right server. You will see your uploaded (public) data.</li>
                                            </ul>
                                        </li>
                                        <li>Private
                                            <ol>
                                                <li>Create an account on the MyDDO page and login</li>
                                                <li>Click on your name in the top right hand corner of the page</li>
                                                <li>Obtain temporary Account Token and Copy it to your clipboard</li>
                                                <li>Switch over to the game and the plugin. On the bottom of the interface is a Link button and space to paste your temporary token.</li>
                                                <li>Click Link to associates your logged in game account to your MyDDO account.</li>
                                                <li>You get an additional button for uploads now, a red one: data will be uploaded but private.</li>
                                                <li>Click Upload</li>
                                                <li>Check out MyDDO page now. Under My Characters you will find a list with your uploaded characters. All public characters are still searchable from the Main page.</li>
                                            </ol>
                                        </li>
                                    </ul>
                                    <ul>Also, you can watch the video linked in <a href="#howto">the how-to FAQ from the previous version of the VoK Launcher.</a></ul>
                                </ol>                                
                            </div>
                        </details>
                        <details>
                            <summary>My character was not found on your website</summary>
                            <div className="faq__content" id="nocharacter">
                                The Vault of Kundarak MyDDO site operates on an <i>Opt-in</i> basis only.  That is, we don't automatically
                                upload any character data for you.  You have to choose to upload the data and can control what is uploaded
                                or not, and whether uploaded information is public or private.
                            </div>
                        </details>
                        <details>
                            <summary>What is this MyDDO thing!?!?</summary>
                            <div className="faq__content" id="players">
                                Many DDO players will remember MyDDO.  It wasn't perfect, but it did allow players to upload their characters to
                                a website where they were publicly visible.  It did much more than that - a blog system, limited inventory
                                viewing, etc, but it was mostly used to keep track of your characters.  It's been long requested by players to
                                bring it back, but Turbine, WB, and now SSG just have not had the resources or desire to invest the resources
                                in such a project.
                            </div>
                        </details>
                        <details>
                            <summary>How do I use it?</summary>
                            <div className="faq__content" id="howto">
                                Check out our <a href="https://www.youtube.com/watch?v=hrqW7VH-C0c">YouTube video</a> with a brief demo.
                            </div>
                        </details>
                        <details>
                            <summary>What data do you collect, and how can I control it?</summary>
                            <div className="faq__content" id="dataCollection">
                                It's probably easiest to just enumerate the points here:
                                <ul>
                                    <li>
                                        This is always an opt-in system.  We will not publish data of players or characters unless that character
                                        is directly uploaded to our servers.  This process isn't even automatic - you have to click a button in our
                                        program to do it.
                                    </li>
                                    <li>
                                        We collect absolutely no personally identifiable information and no account/login information whatsoever.  In
                                        order to identify which characters are yours, we create a unique hash of the ingame account identifier.  This
                                        is only in order to figure out which characters are yours.  It cannot be reversed into the account identifier
                                        that SSG uses to identify your account.  We only ever store this hash on our servers, it is never shown or
                                        sent in web page data when viewing your character.
                                    </li>
                                    <li>
                                        We offer discreet controls over what data is public, what data is private, and what data is not
                                        uploaded at all.  You get to control it.  You can purge or delete it at any time.  You can set visibility to
                                        three different levels:
                                        <ul>
                                            <li><strong>None</strong> - the data is not uploaded.  If you upload with "None" for a given set of data, existing data will be deleted.</li>
                                            <li><strong>Private</strong> - only you, when logged in, can see this data.</li>
                                            <li><strong>Public</strong> - anybody can see this, logged in or anonymous. </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </details>
                        <details>
                            <summary>What is a snapshot?</summary>
                            <div className="faq__content" id="snapshot">
                                A snapshot is a point-in-time copy of your character.  The current visibility options of your character will be used, but may
                                be changed as you desire.  This is intended to be the primary way you would create a sharable build or a backup of your
                                character data before you reincarnate or something.  We also encourage you to use snapshots when sharing builds with friends or
                                on the forums.
                            </div>
                        </details>
                        <details>
                            <summary>Why don't you have [insert data point here] for my character?</summary>
                            <div className="faq__content" id="missingData">
                                We do the best we can, but each data point for a character is work!  Some are easier than others, some take hours of work
                                for a single piece of data.  Some data we have spent dozens of hours trying to collect, but have not been able to do so.  If
                                there is some particular piece of character data you want, you can certainly ask. As of the initial launch, we have
                                included everything we could find.
                            </div>
                        </details>
                        <details>
                            <summary>How do I contact the people who made this?</summary>
                            <div className="faq__content" id="contactDevs">
                                Contact the Vault of Kundarak team on their <a href="https://discord.gg/bfMZnbz">Discord</a> server.
                            </div>
                        </details>
                        <details>
                            <summary>How does all this work?</summary>
                            <div className="faq__content" id="howCome">
                                The team at The Vault of Kundarak has spent over 3 years trying to figure out how to do all this.  It wasn't
                                easy, and we have nothing to gain by doing this for the community except to make the game a little better.  We
                                know this isn't the answer you want, but it's the best we can give.
                            </div>
                        </details>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default FAQ;