function arrayRemove(arr, value) { return arr.filter(function (ele) { return ele !== value; }); }

export default function collapse(category, collapsedNames) {
    if (collapsedNames !== undefined && collapsedNames.length >= 0) {
        if (collapsedNames.includes(category)) {
            // remove 
            this.setState(
                {
                    collapsedNames: arrayRemove(collapsedNames, category)
                });
        } else {
            collapsedNames.push(category)
            // add
            this.setState(
                {
                    collapsedNames: collapsedNames
                });
        }
    }
}
