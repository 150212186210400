import React, { Component } from 'react';
import { Col, Row, Button, Form, InputGroup, FormControl, Container } from 'react-bootstrap';

import axios from 'axios';
import Select from 'react-select';
import Modal from 'react-modal'
import { Redirect } from 'react-router-dom'
import GetErrorStatus from './utils/GetErrorStatus'
import '../css/CharacterControl.css'

const privacyOptions = [
    { value: 0, label: "Delete" },
    { value: 1, label: "Private" },
    { value: 2, label: "Public" },
];

const dot = (color = '#ccc') => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 10,
        width: 10,
    },
});

const dropdownStyles = {
    control: styles => ({ ...styles, backgroundColor: '#333', color: "red" }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isDisabled
                ? "#0E1931"
                : isSelected
                    ? "#333"
                    : isFocused
                        ? "#444"
                        : "#0E1931",

            color: isDisabled ? '#888' : isSelected
                ? "#fff"
                : "#999",

            cursor: isDisabled ? 'not-allowed' : 'default',

            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled && (isSelected ? "#444" : "#333"),
                color: !isDisabled && "#2FD5E7",
            },
            ':hover': {
                ...styles[':hover'],
                backgroundColor: !isDisabled && (isSelected ? "#444" : "#333"),
                color: !isDisabled && "#2FD5E7",
            },
        };
    },
    input: styles => ({ ...styles, ...dot() }),
    placeholder: styles => ({ ...styles, ...dot() }),
    singleValue: (styles) => ({ ...styles, color: "white", ...dot("#2FD5E7") }),
};

Modal.setAppElement('#root')

/*
*   This function will be used to display all of the public characters, on each server.
*/
class CharacterControl extends Component {
    // Starting state:
    constructor(props) {
        super(props)
        this.state = {
            server: null,
            character: null,
            publicVisibility: "1",
            statVisibility: "1",
            skillVisibility: "1",
            featVisibility: "1",
            enhancementVisibility: "1",
            equippedGearVisibility: "1",
            spellVisibility: "1",
            snapshotName: null,
            snapshotDescription: null,
            snapshotModalIsOpen: false,
            deleteModalIsOpen: false,
            redirect: false,
            errorMessage: null,
            HasResults: false
        }
    }

    //static contextType = Auth0Context;

    _getPersonalOptions = async () => {
        //const { isAuthenticated, getTokenSilently } = this.context;
        const { authService } = this.props;
        const isAuthenticated = authService.isAuthenticated();
        if (isAuthenticated) {
            let tokens = authService.getAuthTokens()
            return ({
                headers: {
                    'Authorization': `Bearer ${tokens.access_token}`
                }
            })
        } else {
            return ({
                headers: {}
            })
        };
    }

    _convertWebsiteOptions = (options) => {
        if (options === undefined || options === null) {
            return ""
        }

        // create a new object and set state after parse:

        var newOptions = this.state

        for (const property in options) {
            switch (property) {
                case "public":
                    newOptions.publicVisibility = options[property]
                    break;
                case "showStats":
                    newOptions.statVisibility = options[property]
                    break;
                case "showSpells":
                    newOptions.spellVisibility = options[property]
                    break;
                case "showSkills":
                    newOptions.skillVisibility = options[property]
                    break;
                case "showFeats":
                    newOptions.featVisibility = options[property]
                    break;
                case "showEnhancements":
                    newOptions.enhancementVisibility = options[property]
                    break;
                case "showEquippedGear":
                    newOptions.equippedGearVisibility = options[property]
                    break;
                case "inventory":
                default:
                    break;
            }
        }

        if (newOptions.length > 0) {
            this.setState({
                newOptions
            }, this.forceUpdate())
        }

    }

    changeSelect = (selection, target) => {
        const value = selection.value;
        this.setState({
            [target.name]: value
        }, this.forceUpdate());
    }

    changeInput = (e) => {
        const value = e.target.value;
        this.setState({ [e.target.name]: value });
    }

    submitInput = () => {
        const { server, character, snapshotName } = this.state;
        if (snapshotName !== undefined && snapshotName.length > 0) {
            this._createSnapshot(character, server)
        }
        else {
            this.setState({ errorMessage: "Please provide a snapshot name to continue." })
        }
    }

    _loadProps() {
        const { server, character, websiteOptions, isSnapshot, snapshotName, uniqueName, snapshotDescription } = this.props;
        var snapshotPrivacy = this._convertWebsiteOptions(websiteOptions);
        if (server !== undefined) {
            this.setState({ server: server, character: character, websiteOptions: snapshotPrivacy, snapshotName: snapshotName, uniqueName: uniqueName, snapshotDescription: snapshotDescription, isSnapshot: isSnapshot }, () => {
                console.log("Snapper loading...");
            })
        }
    }

    componentDidMount() {
        this._loadProps()
    }

    componentDidUpdate(prevProps, prevState) {
        const { server, character, uniqueName } = this.props;
        if (prevProps.server !== server || prevProps.character !== character || prevProps.uniqueName !== uniqueName) {
            this._loadProps()
        }
    }

    _createSnapshotPayload = (incoming) => {
        var payload = { VisibilityOptions: {} }

        incoming.publicVisibility ? payload.VisibilityOptions.Public = incoming.publicVisibility : payload.VisibilityOptions.Public = 1
        incoming.statVisibility ? payload.VisibilityOptions.ShowStats = incoming.statVisibility : payload.VisibilityOptions.ShowStats = 1
        incoming.spellVisibility ? payload.VisibilityOptions.ShowSpells = incoming.spellVisibility : payload.VisibilityOptions.ShowSpells = 1
        incoming.featVisibility ? payload.VisibilityOptions.ShowFeats = incoming.featVisibility : payload.VisibilityOptions.ShowFeats = 1
        incoming.enhancementVisibility ? payload.VisibilityOptions.ShowEnhancements = incoming.enhancementVisibility : payload.VisibilityOptions.ShowEnhancements = 1
        incoming.equippedGearVisibility ? payload.VisibilityOptions.ShowEquippedGear = incoming.equippedGearVisibility : payload.VisibilityOptions.ShowEquippedGear = 1
        incoming.skillVisibility ? payload.VisibilityOptions.ShowSkills = incoming.skillVisibility : payload.VisibilityOptions.ShowSkills = 1
        payload.Server = incoming.server
        payload.CharacterName = incoming.character
        payload.Name = incoming.snapshotName
        payload.Description = incoming.snapshotDescription

        return payload;
    }

    _createSnapshot = async (character, server) => {
        // contact the API and show a result of success or failure
        console.log("Creating character snapshot of " + character + " on " + server + ".")

        var apiEndpoint = process.env.REACT_APP_SERVICE;
        var url = apiEndpoint + '/Character/snapshot';
        let options = await this._getPersonalOptions();
        let payload = await this._createSnapshotPayload(this.state)

        console.log("Contacting API: " + url);
        axios.post(url, payload, options)
            .then(res => {
                const snapshotData = res.data;
                this.setState({ snapshotData, snapshotName: undefined, snapshotDescription: undefined }, () => {
                    console.log("Created Snapshot")
                });
                this.hideSnapshotModal()
                if (this.props.loadNewSnapshot !== undefined)
                    this.props.loadNewSnapshot()

            }).catch(e => {
                this.setState({ errorMessage: GetErrorStatus(e.response.status) },
                    () => console.log("Error message: " + this.state.errorMessage));
            });
    }

    _deleteCharacter = async (character, server) => {
        // contact the API and show a result of success or failure
        console.log("Deleting " + character + " on " + server + ".")

        var apiEndpoint = process.env.REACT_APP_SERVICE;
        var url = apiEndpoint + '/Character/' + server + '/' + character;
        let options = await this._getPersonalOptions();

        console.log("Contacting API: " + url);
        axios.delete(url, options)
            .then(res => {
                //const responseData = res.data;
                this.hideDeleteModal();
                this.setState({ redirect: true }, () => {
                    console.log("Deleted!")
                });
            }).catch(e => {
                this.setState({ errorMessage: GetErrorStatus(e.response.status) },
                    () => console.log("Error message: " + this.state.errorMessage));
            });
    }

    _deleteCharacterSnapshot = async (character, server) => {
        const { uniqueName } = this.state;
        if (uniqueName !== undefined && uniqueName.length > 0) {
            // contact the API and show a result of success or failure
            console.log("Deleting snapshot " + uniqueName + " of " + character + " on " + server + ".")

            var apiEndpoint = process.env.REACT_APP_SERVICE;
            var url = apiEndpoint + '/Character/' + server + '/' + character + '/snapshots/p/' + uniqueName;
            let options = await this._getPersonalOptions();

            console.log("Contacting API: " + url);
            axios.delete(url, options)
                .then(res => {
                    //const responseData = res.data;
                    this.hideDeleteModal();
                    this.setState({ redirect: true }, () => {
                        console.log("Deleted!")
                    });
                }).catch(e => {
                    this.setState({ errorMessage: GetErrorStatus(e.response.status) },
                        () => console.log("Error message: " + this.state.errorMessage));
                });
        }
    }

    showSnapshotModal = () => {
        this.setState({ snapshotModalIsOpen: true }
        );
        this.forceUpdate();
    }

    hideSnapshotModal = () => {
        this.setState({ snapshotModalIsOpen: false }
        );
        this.forceUpdate();
    }

    showDeleteModal = (isSnapshot) => {
        this.setState({ deleteModalIsOpen: true, isSnapshot: isSnapshot }
        );
        this.forceUpdate();
    }

    hideDeleteModal = () => {
        this.setState({ deleteModalIsOpen: false }
        );
        this.forceUpdate();
    }

    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to='/' />
        }
    }

    render() {
        //enhancementVisibility, equippedGearVisibility, spellVisibility
        const { errorMessage, character, server, snapshotModalIsOpen, deleteModalIsOpen, snapshotName, isSnapshot,
            publicVisibility, statVisibility, skillVisibility, featVisibility
        } = this.state;
        return (
            <Col>
                {this.renderRedirect()}
                <Modal className="modal-main modal-delete" overlayClassName="modal" isOpen={deleteModalIsOpen} onRequestClose={this.hideDeleteModal}>
                    <div className="draggable col">
                        {errorMessage ? errorMessage : null}
                        <InputGroup>
                            <Container>
                                <Row>
                                    <InputGroup.Append>
                                        <label>Are you sure that you want to delete {isSnapshot ? `snapshot ${snapshotName} from ${character} ` : character} on {server}?</label>
                                    </InputGroup.Append>
                                    <InputGroup.Append>
                                        <Button className="modal-bright-button" onClick={this.hideDeleteModal}>
                                            Cancel
                                    </Button>
                                    </InputGroup.Append>
                                    <InputGroup.Append className="delete-data">
                                        {isSnapshot ?
                                            <Button id="deleteCharacter" name="deleteCharacter" variant="success" onClick={() => this._deleteCharacterSnapshot(character, server)}>
                                                Confirm Snapshot Deletion
                                        </Button>
                                            :
                                            <Button id="deleteCharacter" name="deleteCharacter" variant="success" onClick={() => this._deleteCharacter(character, server)}>
                                                Confirm Character Deletion
                                    </Button>
                                        }
                                    </InputGroup.Append>
                                </Row>
                            </Container>
                        </InputGroup>
                    </div>
                </Modal>
                <Modal className="modal-main modal-snapshot" overlayClassName="modal" isOpen={snapshotModalIsOpen} onRequestClose={this.hideSnapshotModal}>
                    <div className="draggable col">
                        <Form>
                            <InputGroup>
                                <Container>
                                    {errorMessage ?
                                        <Row className="SnapshotError">{errorMessage}</Row>
                                        : null}
                                    <InputGroup.Append>
                                        <label htmlFor="snapshotName">Snapshot Name:</label>
                                        <FormControl
                                            name="snapshotName"
                                            id="snapshotName"
                                            type="input"
                                            className="CharacterInput"
                                            placeholder="Snapshot name"
                                            aria-label="Snapshot name"
                                            aria-describedby="basic-addon2"
                                            onChange={this.changeInput.bind()}
                                            onKeyPress={event => {
                                                if (event.key === "Enter") {
                                                    this.submitInput();
                                                }
                                            }}
                                        />
                                    </InputGroup.Append>
                                    <InputGroup.Append>
                                        <label htmlFor="snapshotDescription">Snapshot Description:</label>
                                        <FormControl
                                            type="input"
                                            name="snapshotDescription"
                                            id="snapshotDescription"
                                            className="CharacterInput"
                                            placeholder="Snapshot Description"
                                            onChange={this.changeInput.bind()}
                                        />
                                    </InputGroup.Append>
                                    <InputGroup.Append>
                                        <label htmlFor="publicVisibility">Public Profile Visibility:</label>
                                        <Select
                                            name="publicVisibility"
                                            id="publicVisibility"
                                            className="ModalInput"
                                            styles={dropdownStyles}
                                            closeMenuOnSelect={true}
                                            value={privacyOptions[publicVisibility]}
                                            options={privacyOptions}
                                            placeholder="Public Data"
                                            onChange={this.changeSelect}
                                        />
                                    </InputGroup.Append>
                                    <InputGroup.Append>
                                        <label htmlFor="statVisibility">Stat Profile Visibility:</label>
                                        <Select
                                            name="statVisibility"
                                            id="statVisibility"
                                            className="ModalInput"
                                            styles={dropdownStyles}
                                            closeMenuOnSelect={true}
                                            value={privacyOptions[statVisibility]}
                                            options={privacyOptions}
                                            placeholder="Stats"
                                            onChange={this.changeSelect}
                                        />
                                    </InputGroup.Append>
                                    <InputGroup.Append>
                                        <label htmlFor="skillVisibility">Skills Profile Visibility:</label>
                                        <Select
                                            name="skillVisibility"
                                            id="skillVisibility"
                                            className="ModalInput"
                                            styles={dropdownStyles}
                                            closeMenuOnSelect={true}
                                            value={privacyOptions[skillVisibility]}
                                            options={privacyOptions}
                                            placeholder="Skills"
                                            onChange={this.changeSelect}
                                        />
                                    </InputGroup.Append>
                                    <InputGroup.Append>
                                        <label htmlFor="featVisibility">Feat Profile Visibility:</label>
                                        <Select
                                            name="featVisibility"
                                            id="featVisibility"
                                            className="ModalInput"
                                            styles={dropdownStyles}
                                            closeMenuOnSelect={true}
                                            value={privacyOptions[featVisibility]}
                                            options={privacyOptions}
                                            placeholder="Feats"
                                            onChange={this.changeSelect}
                                        />
                                    </InputGroup.Append>
                                    <br />
                                    {/*<InputGroup.Append>
                                    <label htmlFor="enhancementVisibility">Enhancment Profile Visibility:</label>
                                    <Select
                                        name="enhancementVisibility"
                                        id="enhancementVisibility"
                                        className="ModalInput"
                                        styles={dropdownStyles}
                                        closeMenuOnSelect={true}
                                        value={privacyOptions[enhancementVisibility]}
                                        options={privacyOptions}
                                        placeholder="Enhancments"
                                        onChange={this.changeSelect}
                                    />
                                </InputGroup.Append>
                                <InputGroup.Append>
                                    <label htmlFor="equippedGearVisibility">Equipped Gear Profile Visibility:</label>
                                    <Select
                                        name="equippedGearVisibility"
                                        id="equippedGearVisibility"
                                        className="ModalInput"
                                        styles={dropdownStyles}
                                        closeMenuOnSelect={true}
                                        value={privacyOptions[equippedGearVisibility]}
                                        options={privacyOptions}
                                        placeholder="Equipped Gear"
                                        onChange={this.changeSelect}
                                    /></InputGroup.Append>
                                <InputGroup.Append>
                                    <label htmlFor="spellVisibility">Spell Profile Visibility:</label>
                                    <Select
                                        name="spellVisibility"
                                        id="spellVisibility"
                                        className="ModalInput"
                                        styles={dropdownStyles}
                                        closeMenuOnSelect={true}
                                        value={privacyOptions[spellVisibility]}
                                        options={privacyOptions}
                                        placeholder="Spells"
                                        onChange={this.changeSelect}
                                    />
                                </InputGroup.Append>*/}
                                    <Row className="userControls">
                                        <Button className="" variant="dark" onClick={this.hideSnapshotModal}>Cancel</Button>
                                        <Button className="" variant="dark" onClick={() => this.submitInput()}>
                                            Create snapshot
                                        </Button>
                                    </Row>
                                </Container>
                            </InputGroup>
                        </Form>
                    </div>
                </Modal>
                <Row className="userControls">
                    {!isSnapshot ?
                        <Button variant="dark" onClick={this.showSnapshotModal}>
                            Create snapshot
                        </Button>
                        : null}
                    <Button variant="dark" onClick={() => this.showDeleteModal(isSnapshot)}>
                        Delete {isSnapshot ? "Snapshot" : null}
                    </Button>
                </Row>
            </Col >
        );
    }
}

export default CharacterControl;