export default function GetErrorStatus(error) {
    switch (error) {
        default:
        case 200:
            return "No problem?"
        case 400:
            return "You did not suply enough data to satisfy the requirements. Check for empty input boxes.";
        case 404:
            return "The requested Character was not found.";
        case 405:
            return "The service is not available at this time.";
        case 500:
            return "The server had difficulty with your request -- please try again later and contact us on Discord if the issue persists."
    }
}